import assert from 'assert';
import _ from 'lodash';
import moment from 'moment';
import { ErrorList } from 'async-validator';
import { DBModel } from './model';
import { DBSchema, DBSchemaItem } from './schema';
import { PlainObject } from '@/util';

const dateFormat = 'DD/MM/YY HH:mm';

export interface IDocument {
  id: string;
}

export interface IDocumentTimestamps {
  createdAt: string;
  updatedAt: string;
}

export interface IDocumentFlags {
  isNew: boolean;
  isCommitted: boolean;
  isRemoved: boolean;
}

export abstract class DBDocument<T extends DBDocument<T, I>, I extends IDocument> {
  private $flags: IDocumentFlags;
  private $isNew: boolean;
  private $isCommitted: boolean;
  private $isRemoved: boolean;
  private $createdAt: Date | null = null;
  private $updatedAt: Date | null = null;

  protected $model: DBModel<T, I>;

  id: string = '';

  /**
   * Constructor
   *
   * Base constructor
   *
   * @access public
   * @param {DBModel<T>} model The model that is associated with the document
   */
  constructor(model: DBModel<T, I>) {
    this.$model = model;
    this.$flags = {
      isNew: true,
      isCommitted: false,
      isRemoved: false,
    };
    this.$isNew = true;
    this.$isCommitted = false;
    this.$isRemoved = false;

    /**
     * Handle referenced properties
     */
    const schema: DBSchema = this.$model.getSchema();

    _.forEach(schema, (item: DBSchemaItem | DBSchemaItem[], key: string) => {
      if (_.isArray(item) || !item.ref) {
        return;
      }

      let realVal: any;

      Object.defineProperty(this, item.ref, {
        get: () => realVal,
        set: val => {
          realVal = val;
          this[key as keyof DBDocument<T, I>] = val ? val.id : '';
        },
      });
    });
  }

  /**
   * Get the setup schema keys
   *
   * Method will return an array of the setup schema keys (setup fields)
   *
   * @access private
   * @param {boolean} includeReferences True to include referenced data, false not to (default is false)
   * @return {Array<keyof T>} An array of the setup schema keys
   */
  private $getKeys(includeReferences?: boolean): Array<keyof T> {
    const keys: Array<keyof T> = ['id'];
    const schema: DBSchema = this.$model.getSchema();

    for (const key in schema) {
      keys.push(key as keyof T);

      if (includeReferences && !_.isArray(schema[key]) && (schema[key] as DBSchemaItem).ref) {
        keys.push((schema[key] as DBSchemaItem).ref as keyof T);
      }
    }

    return keys as Array<keyof T>;
  }

  /**
   * Convert the whole document to a plain object
   *
   * Method will convert the whole document to a plain object
   *
   * @access protected
   * @param {boolean} includeReferences True to include referenced data, false not to (default is false)
   * @return {I} The plain object
   */
  protected $toObject(includeReferences?: boolean): I {
    const obj: Record<keyof T, any> = {} as Record<keyof T, any>;
    const keys = this.$getKeys(includeReferences);

    keys.forEach(key => {
      obj[key] = this[key as keyof DBDocument<T, I>];
    });

    if (!obj.id) {
      delete obj.id;
    }

    return JSON.parse(JSON.stringify(obj)) as I;
  }

  /**
   * Get the flags
   *
   * Method will get the flags on the document
   *
   * @access public
   * @return {IDocumentFlags} The document flags
   */
  getFlags(): IDocumentFlags {
    return {
      isNew: this.$isNew,
      isCommitted: this.$isCommitted,
      isRemoved: this.$isRemoved,
    };
  }

  /**
   * Set the flags
   *
   * Method will set the flags on the document
   *
   * @access public
   * @param {Partial<IDocumentFlags>} flags The document flags or part of
   * @return {IDocumentFlags} The updated document flags
   */
  setFlags(flags: Partial<IDocumentFlags>): IDocumentFlags {
    this.$flags = { ...this.$flags, ...flags };

    return this.getFlags();
  }

  /**
   * Is this document a new document?
   *
   * Method will check to see if the document is a new document (does NOT exists in the DB) or
   * not (DOES exists in the DB)
   *
   * @access public
   * @return {boolean} True if the document is new, false if not
   */
  isNew(): boolean {
    return this.$flags.isNew;
  }

  /**
   * @TODO Check mutate on ALL fields
   *
   * Has this document comitted its updated data to the DB?
   *
   * Method will return true if this document has been inserted/uppdated to the database and data has NOT been
   * mutated afterwards. Return false if data has been mutated without being saved
   *
   * @access public
   * @return {boolean} True if the document is new, false if not
   */
  isCommitted(): boolean {
    return this.$flags.isCommitted;
  }

  /**
   * Has this document been removed/deleted?
   *
   * Method will return true if this document has been removed/deleted
   *
   * @access public
   * @return {boolean} True if the document has been removed/deleted, false if not
   */
  isRemoved(): boolean {
    return this.$flags.isRemoved;
  }

  /**
   * Convert the whole document to a plain object WITH the referenced data
   *
   * Method will convert the whole document into a plain (JSON) object, including all referenced
   * data
   *
   * @access public
   * @return {Partial<I>} The plain object
   */
  toJSON(): Partial<I> {
    return this.$toObject(true);
  }

  /**
   * Convert the document into a plain object WITHOUT the referenced data
   *
   * Method will work the same as `toJSON()` except that it will strip out any referenced data, so
   * it can be used for db mutation variables (EG: Safe to insert it into db as is)
   *
   * @access public
   * @return {Partial<I>} The plain object
   */
  toData(): Partial<I> {
    return this.$toObject(false);
  }

  /**
   * Get the created date
   *
   * Getter for getting the created date
   *
   * @access public
   * @param {string} [asRaw] True to get raw date object, fale for formatted string. Default is false
   * @return {string | Date | null} The created date if default, else null
   */
  getCreatedDate(asRaw?: boolean): string | Date | null {
    if (!this.$createdAt) {
      return null;
    }

    if (asRaw) {
      return this.$createdAt;
    } else {
      return moment(this.$createdAt).format(dateFormat);
    }
  }

  /**
   * Get the updated date
   *
   * Getter for getting the updated date
   *
   * @access public
   * @param {string} [asRaw] True to get raw date object, false for formatted string. Default is false
   * @return {string | Date | null} The updated date if default, else null
   */
  getUpdatedDate(asRaw?: boolean): string | Date | null {
    if (!this.$updatedAt) {
      return null;
    }

    if (asRaw) {
      return this.$updatedAt;
    } else {
      return moment(this.$updatedAt).format(dateFormat);
    }
  }

  /**
   * Merge plain object into document
   *
   * Method will merge plain object data into the current document, overwriting any data
   *
   * @access public
   * @param {Partial<I> & IDocumentTimestamps} data The data to merge/overwrite with
   * @return {void}
   */
  merge(data: Partial<I> & IDocumentTimestamps): void {
    this.preLoad(data);

    this.$getKeys(true).forEach(key => {
      if (key in data) {
        this[key as keyof DBDocument<T, I>] = (data as PlainObject)[key as string];
      }
    });

    if (data.createdAt) {
      this.$createdAt = new Date(data.createdAt);
    }

    if (data.updatedAt) {
      this.$updatedAt = new Date(data.updatedAt);
    }

    this.postLoad();
  }

  /**
   * Run validation
   *
   * Method will run validation and return either void on success OR an array of errors
   *
   * @access public
   * @return {Promise<void | ErrorList>} Void on success else an array of errors
   */
  async validate(): Promise<void | ErrorList> {
    return this.$model.validate((this as unknown) as T);
  }

  /**
   * Pre load hook
   *
   * Internal method to implement any pre load hook funactionality. Method will be called on
   * finding/querying methods that load the document object
   *
   * @access public
   * @param {Partial<I>} _data The data from the DB
   * @return {void}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public preLoad(_data: Partial<I>): void {
    return;
  }

  /**
   * Post save hook
   *
   * Internal method to implement any post load hook funactionality. Method will be called on
   * finding/querying methods that load the document object
   *
   * @access public
   * @return {void}
   */
  public postLoad(): void {
    return;
  }

  /**
   * Pre save hook
   *
   * Internal method to implement any pre save hook funactionality. Throwing an error here will
   * stop the save. Method will be called on all inserts and updates
   *
   * @access public
   * @async
   * @param {boolean} isUpdate True if this is an update, false for insert
   * @return {Promise<void>}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async preSave(_isUpdate: boolean): Promise<void> {
    return;
  }

  /**
   * Post save hook
   *
   * Internal method to implement any post save hook funactionality. Throwing an error here will
   * be IGNORED as the DB save has already been completed. Method will be called on all inserts
   * and updates
   *
   * @access public
   * @async
   * @param {T} document The document to check
   * @return {Promise<void>}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async postSave(_isUpdate: boolean): Promise<void> {
    return;
  }

  /**
   * Pre remove hook
   *
   * Internal method to implement any pre remove hook funactionality. Throwing an error here will
   * stop the remove
   *
   * @access public
   * @async
   * @return {Promise<void>}
   */
  public async preRemove(): Promise<void> {
    return;
  }

  /**
   * Post remove hook
   *
   * Internal method to implement any post remove hook funactionality. Throwing an error here will
   * be IGNORED as the DB remove has already been completed
   *
   * @access public
   * @async
   * @return {Promise<void>}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async postRemove(_data: I): Promise<void> {
    return;
  }

  /**
   * Save a document object
   *
   * Method is shorthand wrapper for calling either `insert()` or `update()` method on the associated
   * model, depening if the document is new or not
   *
   * @access public
   * @return {Promise<void>}
   */
  public async save(): Promise<void> {
    assert(!this.$flags.isRemoved, 'Unable to save document that is already removed');

    let opType: keyof DBModel<T, I>;

    const isUpdate = !this.$flags.isNew;

    if (isUpdate) {
      opType = 'update';
    } else {
      opType = 'insert';
    }

    await this.preSave(isUpdate);

    await this.$model[opType]((this as unknown) as T);

    try {
      await this.postSave(isUpdate);
    } catch (e) {
      const msg =
        'Error thrown in postSave hook. Please do NOT throw in there as the data will be saved in the DB but end operation will fail.\n' +
        'Original error: ' +
        (e as Error).message;

      (e as Error).message = msg;

      throw e;
    }
  }

  /**
   * Remove the document from the db
   *
   * Method will call the `remove()` method on the associated model and mark this document as deleted
   *
   * @access public
   * @return {Promise<I>}
   */
  public async remove(): Promise<I> {
    assert(this.id, 'Cannot remove as this document is not saved to the DB');
    assert(!this.$flags.isRemoved, 'This document has already been removed');

    await this.preRemove();

    const rtn: I = await this.$model.remove(this.id);

    this.$flags.isRemoved = true;

    try {
      await this.postRemove(rtn);
    } catch (e) {
      const msg =
        'Error thrown in postRemove hook. Please do NOT throw in there as the data will be saved in the DB but end operation will fail.\n' +
        'Original error: ' +
        (e as Error).message;

      (e as Error).message = msg;

      throw e;
    }

    return rtn;
  }
}
