import { DBSchema } from './lib/schema';

export { IS3Object } from '@/util';

export const S3ObjectDefaultValue = {
  key: '',
  name: '',
  mimetype: '',
};

export const S3ObjectSchema: DBSchema = {
  key: {
    type: 'string',
  },
  name: {
    type: 'string',
  },
  mimetype: {
    type: 'string',
  },
};
