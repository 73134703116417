import { RouteConfig } from 'vue-router';

export const getFullPath = (route: RouteConfig, basePath: string): string => {
  const fullPath: string = (basePath + '/' + route.path)
    .split('/')
    .filter(x => x.trim() !== '')
    .join('/');

  return '/' + fullPath;
};
