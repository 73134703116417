import Vue from 'vue';
import Vuex from 'vuex';

import { config } from 'vuex-module-decorators';

config.rawError = true;

import { IAppState } from './modules/app';
import { IUserState } from './modules/user';
import { IQpointState } from './modules/qpoint';
import { IBrandState } from './modules/brand';
import { IExerciseState } from './modules/exercise';
import { IWorkoutState } from './modules/workout';
import { IPartnerState } from './modules/partner';
import { IPartnerBrandState } from './modules/partnerbrand';

Vue.use(Vuex);

export interface RootState {
  app: IAppState;
  user: IUserState;
  qpoint: IQpointState;
  brand: IBrandState;
  exercise: IExerciseState;
  workout: IWorkoutState;
  partner: IPartnerState;
  partnerBrand: IPartnerBrandState;
}

/**
 * Declare empty store first, dynamically register all modules later.
 */
export default new Vuex.Store<RootState>({});
