import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';

export interface IPartner extends IDocument {
  name: string;
  variations: string[];
}

export class PartnerDocument extends DBDocument<PartnerDocument, IPartner> implements IPartner {
  name: string = '';
  variations: string[] = [];
}

export class PartnerModel extends DBModel<PartnerDocument, IPartner> {
  async createNew(data?: Partial<IPartner>): Promise<PartnerDocument> {
    const doc: PartnerDocument = await this.createWithId(data);

    doc.variations = [''];

    return doc;
  }
}

const setup: DBSchema = {
  name: {
    type: 'string',
    required: true,
  },
  variations: {
    type: 'array',
    defaultField: {
      type: 'string',
      required: true,
    },
  },
};

const partnerModel = new PartnerModel('Partner', PartnerDocument, setup);

export default partnerModel;
