import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { PartnerBrandModel, PartnerBrandDocument } from '@/models';

export interface IPartnerBrandState {
  current: PartnerBrandDocument | null;
  list: PartnerBrandDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'partnerbrand' })
class PartnerBrandStore extends VuexModule implements IPartnerBrandState {
  public current: PartnerBrandDocument | null = null;
  public list: PartnerBrandDocument[] = [];

  @Mutation
  SET_CURRENT(partnerbrand: PartnerBrandDocument) {
    this.current = partnerbrand;
  }

  @Mutation
  SET_LIST(partnerbrands: PartnerBrandDocument[]) {
    this.list = partnerbrands;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<PartnerBrandDocument[]> {
    return PartnerBrandModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(partnerId: string): Promise<PartnerBrandDocument> {
    return PartnerBrandModel.createNew(partnerId);
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<PartnerBrandDocument | void> {
    return PartnerBrandModel.findById(id);
  }
}

export default getModule(PartnerBrandStore);
