








import { Component, Vue, Prop } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { getFullPath } from './util';

@Component({
  name: 'NavItemLink',
})
export default class extends Vue {
  @Prop({ required: true }) private route!: RouteConfig;
  @Prop({ default: '' }) private basePath!: string;

  get title(): string {
    return this.route.meta?.title || '';
  }

  get fullPath(): string {
    return getFullPath(this.route, this.basePath);
  }
}
