/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const createQpoint = /* GraphQL */ `
  mutation CreateQpoint(
    $input: CreateQpointInput!
    $condition: ModelQpointConditionInput
  ) {
    createQpoint(input: $input, condition: $condition) {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const updateQpoint = /* GraphQL */ `
  mutation UpdateQpoint(
    $input: UpdateQpointInput!
    $condition: ModelQpointConditionInput
  ) {
    updateQpoint(input: $input, condition: $condition) {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const deleteQpoint = /* GraphQL */ `
  mutation DeleteQpoint(
    $input: DeleteQpointInput!
    $condition: ModelQpointConditionInput
  ) {
    deleteQpoint(input: $input, condition: $condition) {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise(
    $input: CreateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    createExercise(input: $input, condition: $condition) {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise(
    $input: UpdateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    updateExercise(input: $input, condition: $condition) {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const deleteExercise = /* GraphQL */ `
  mutation DeleteExercise(
    $input: DeleteExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    deleteExercise(input: $input, condition: $condition) {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const createWorkout = /* GraphQL */ `
  mutation CreateWorkout(
    $input: CreateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    createWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateWorkout = /* GraphQL */ `
  mutation UpdateWorkout(
    $input: UpdateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    updateWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteWorkout = /* GraphQL */ `
  mutation DeleteWorkout(
    $input: DeleteWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    deleteWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBulkOps = /* GraphQL */ `
  mutation CreateBulkOps(
    $input: CreateBulkOpsInput!
    $condition: ModelBulkOpsConditionInput
  ) {
    createBulkOps(input: $input, condition: $condition) {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateBulkOps = /* GraphQL */ `
  mutation UpdateBulkOps(
    $input: UpdateBulkOpsInput!
    $condition: ModelBulkOpsConditionInput
  ) {
    updateBulkOps(input: $input, condition: $condition) {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteBulkOps = /* GraphQL */ `
  mutation DeleteBulkOps(
    $input: DeleteBulkOpsInput!
    $condition: ModelBulkOpsConditionInput
  ) {
    deleteBulkOps(input: $input, condition: $condition) {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createRawVideoExportLog = /* GraphQL */ `
  mutation CreateRawVideoExportLog(
    $input: CreateRawVideoExportLogInput!
    $condition: ModelRawVideoExportLogConditionInput
  ) {
    createRawVideoExportLog(input: $input, condition: $condition) {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRawVideoExportLog = /* GraphQL */ `
  mutation UpdateRawVideoExportLog(
    $input: UpdateRawVideoExportLogInput!
    $condition: ModelRawVideoExportLogConditionInput
  ) {
    updateRawVideoExportLog(input: $input, condition: $condition) {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRawVideoExportLog = /* GraphQL */ `
  mutation DeleteRawVideoExportLog(
    $input: DeleteRawVideoExportLogInput!
    $condition: ModelRawVideoExportLogConditionInput
  ) {
    deleteRawVideoExportLog(input: $input, condition: $condition) {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const createPartnerBrand = /* GraphQL */ `
  mutation CreatePartnerBrand(
    $input: CreatePartnerBrandInput!
    $condition: ModelPartnerBrandConditionInput
  ) {
    createPartnerBrand(input: $input, condition: $condition) {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePartnerBrand = /* GraphQL */ `
  mutation UpdatePartnerBrand(
    $input: UpdatePartnerBrandInput!
    $condition: ModelPartnerBrandConditionInput
  ) {
    updatePartnerBrand(input: $input, condition: $condition) {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePartnerBrand = /* GraphQL */ `
  mutation DeletePartnerBrand(
    $input: DeletePartnerBrandInput!
    $condition: ModelPartnerBrandConditionInput
  ) {
    deletePartnerBrand(input: $input, condition: $condition) {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPartnerWorkout = /* GraphQL */ `
  mutation CreatePartnerWorkout(
    $input: CreatePartnerWorkoutInput!
    $condition: ModelPartnerWorkoutConditionInput
  ) {
    createPartnerWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePartnerWorkout = /* GraphQL */ `
  mutation UpdatePartnerWorkout(
    $input: UpdatePartnerWorkoutInput!
    $condition: ModelPartnerWorkoutConditionInput
  ) {
    updatePartnerWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePartnerWorkout = /* GraphQL */ `
  mutation DeletePartnerWorkout(
    $input: DeletePartnerWorkoutInput!
    $condition: ModelPartnerWorkoutConditionInput
  ) {
    deletePartnerWorkout(input: $input, condition: $condition) {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
