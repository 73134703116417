import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { ExerciseModel, ExerciseDocument } from '@/models';

type fileAction = 'add' | 'remove';

export interface IExerciseState {
  current: ExerciseDocument | null;
  list: ExerciseDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'exercise' })
class ExerciseStore extends VuexModule implements IExerciseState {
  public current: ExerciseDocument | null = null;
  public list: ExerciseDocument[] = [];

  @Mutation
  SET_CURRENT(exercise: ExerciseDocument) {
    this.current = exercise;
  }

  @Mutation
  SET_LIST(exercises: ExerciseDocument[]) {
    this.list = exercises;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<ExerciseDocument[]> {
    return ExerciseModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<ExerciseDocument> {
    const doc = await ExerciseModel.findById(id);

    if (!doc) {
      throw new Error(`Cannot find exercise with ID of ${id}`);
    }

    return doc;
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(data?: any): Promise<ExerciseDocument> {
    return ExerciseModel.createNew(data);
  }
}

export default getModule(ExerciseStore);
