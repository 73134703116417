import { BulkOpsType } from '@/constants';
import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';

export interface IBulkOps extends IDocument {
  type: BulkOpsType;
  success: string[];
  error: string[];
  isDeleted: boolean;
}

export class BulkOpsDocument extends DBDocument<BulkOpsDocument, IBulkOps> implements IBulkOps {
  type: BulkOpsType = BulkOpsType.IMPORT_RAW_VIDEOS;
  success: string[] = [];
  error: string[] = [];
  isDeleted: boolean = false;
}

export class BulkOpsModel extends DBModel<BulkOpsDocument, IBulkOps> {
  findActive(): Promise<BulkOpsDocument[]> {
    const filter: any = {
      isDeleted: { eq: false },
    };

    return this.find(filter);
  }
}

const setup: DBSchema = {
  type: {
    type: 'enum',
    required: true,
    enum: Object.keys(BulkOpsType),
  },
  success: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  error: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  isDeleted: {
    type: 'boolean',
  },
};

const bulkOpsModel = new BulkOpsModel('BulkOps', BulkOpsDocument, setup);

export default bulkOpsModel;
