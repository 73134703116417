import {
  CategoryType,
  ProductType,
  MovementType,
  ExerciseDurationTime,
  BrandWorkoutSetupType,
  BrandWorkoutSetupTypeList,
  WarmUpCoolDownMovementType,
} from '@/constants';
import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';
import { IS3Object, S3ObjectSchema, S3ObjectDefaultValue } from '../shared';
import WorkoutModel from '../workout';

export interface IBrandWorkoutSetupExercise {
  exerciseId: string;
  movementType: MovementType;
  duration: number;
}

export interface IBrandWorkoutSetup {
  type: BrandWorkoutSetupType;
  setup: IBrandWorkoutSetupExercise[];
}

export interface IBrandProductMovementSetup {
  productType: ProductType;
  movementTypes: MovementType[];
}

export interface IBrandTrainer {
  name: string;
  inits: string;
}

export interface IBrand extends IDocument {
  name: string;
  category: CategoryType;
  productMovementSetup: IBrandProductMovementSetup[];
  trainer: IBrandTrainer;
  workoutSetup: IBrandWorkoutSetup[];
  logoFile: IS3Object;
  iconFile: IS3Object;
  excelTemplateFile: IS3Object;
  longWarmupVideoFile: IS3Object;
  active: boolean;
}

export class BrandDocument extends DBDocument<BrandDocument, IBrand> implements IBrand {
  name: string = '';
  category: CategoryType = CategoryType.CARDIO;
  productMovementSetup: IBrandProductMovementSetup[] = [];
  trainer: IBrandTrainer = {
    name: '',
    inits: '',
  };
  workoutSetup: IBrandWorkoutSetup[] = [];
  logoFile: IS3Object = S3ObjectDefaultValue;
  iconFile: IS3Object = S3ObjectDefaultValue;
  excelTemplateFile: IS3Object = S3ObjectDefaultValue;
  longWarmupVideoFile: IS3Object = S3ObjectDefaultValue;
  active: boolean = true;

  postLoad(): void {
    const existingTypes: BrandWorkoutSetupType[] = this.workoutSetup.map(x => x.type);
    const newTypes: BrandWorkoutSetupType[] = (Object.keys(BrandWorkoutSetupType) as BrandWorkoutSetupType[]).filter(x => !existingTypes.includes(x));

    newTypes.forEach(type => this.workoutSetup.push({ type, setup: [] }));

    // this.workoutSetup.forEach(x => {
    //   if (x.setup.length > 0) {
    //     return;
    //   }

    //   x.setup.push({
    //     exerciseId: '',
    //     movementType: WarmUpCoolDownMovementType[0],
    //     duration: ExerciseDurationTime[0],
    //   });
    // });

    /**
     * Keep them in order
     */
    const keyOrder = Object.keys(BrandWorkoutSetupTypeList);

    this.workoutSetup.sort((a, b) => keyOrder.indexOf(a.type) - keyOrder.indexOf(b.type));
  }

  async preRemove(): Promise<void> {
    const totalWorkouts = await WorkoutModel.count({ workoutBrandId: { eq: this.id } });

    if (totalWorkouts > 0) {
      throw new Error('Unable to delete as this brand current has workouts');
    }
  }
}

export class BrandModel extends DBModel<BrandDocument, IBrand> {
  async createNew(data?: Partial<IBrand>): Promise<BrandDocument> {
    return this.createWithId(data);
  }
}

const setup: DBSchema = {
  name: {
    type: 'string',
    required: true,
  },
  category: {
    type: 'enum',
    required: true,
    enum: Object.keys(CategoryType),
  },
  productMovementSetup: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        productType: {
          type: 'enum',
          enum: Object.keys(ProductType),
        },
        movementTypes: {
          type: 'array',
          defaultField: {
            type: 'enum',
            enum: Object.keys(MovementType),
          },
        },
      },
    },
  },
  trainer: {
    type: 'object',
    fields: {
      name: {
        type: 'string',
        required: true,
      },
      inits: {
        type: 'string',
        required: true,
      },
    },
  },
  workoutSetup: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        type: {
          type: 'enum',
          required: true,
          enum: Object.keys(BrandWorkoutSetupType),
        },
        setup: {
          type: 'array',
          defaultField: {
            type: 'object',
            fields: {
              exerciseId: {
                type: 'string',
                required: true,
              },
              movementType: {
                type: 'enum',
                required: true,
                enum: Object.keys(MovementType),
              },
              duration: {
                type: 'enum',
                required: true,
                enum: ExerciseDurationTime,
              },
            },
          },
        },
      },
    },
  },
  logoFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  iconFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  excelTemplateFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  longWarmupVideoFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  active: {
    type: 'boolean',
  },
};

const brandModel = new BrandModel('Brand', BrandDocument, setup);

export default brandModel;
