/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onCreateQpoint = /* GraphQL */ `
  subscription OnCreateQpoint {
    onCreateQpoint {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQpoint = /* GraphQL */ `
  subscription OnUpdateQpoint {
    onUpdateQpoint {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQpoint = /* GraphQL */ `
  subscription OnDeleteQpoint {
    onDeleteQpoint {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand {
    onCreateBrand {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand {
    onUpdateBrand {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBrand = /* GraphQL */ `
  subscription OnDeleteBrand {
    onDeleteBrand {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExercise = /* GraphQL */ `
  subscription OnCreateExercise {
    onCreateExercise {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExercise = /* GraphQL */ `
  subscription OnUpdateExercise {
    onUpdateExercise {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExercise = /* GraphQL */ `
  subscription OnDeleteExercise {
    onDeleteExercise {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkout = /* GraphQL */ `
  subscription OnCreateWorkout {
    onCreateWorkout {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateWorkout = /* GraphQL */ `
  subscription OnUpdateWorkout {
    onUpdateWorkout {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteWorkout = /* GraphQL */ `
  subscription OnDeleteWorkout {
    onDeleteWorkout {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateBulkOps = /* GraphQL */ `
  subscription OnCreateBulkOps {
    onCreateBulkOps {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBulkOps = /* GraphQL */ `
  subscription OnUpdateBulkOps {
    onUpdateBulkOps {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBulkOps = /* GraphQL */ `
  subscription OnDeleteBulkOps {
    onDeleteBulkOps {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRawVideoExportLog = /* GraphQL */ `
  subscription OnCreateRawVideoExportLog {
    onCreateRawVideoExportLog {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRawVideoExportLog = /* GraphQL */ `
  subscription OnUpdateRawVideoExportLog {
    onUpdateRawVideoExportLog {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRawVideoExportLog = /* GraphQL */ `
  subscription OnDeleteRawVideoExportLog {
    onDeleteRawVideoExportLog {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner {
    onCreatePartner {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner {
    onUpdatePartner {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner {
    onDeletePartner {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePartnerBrand = /* GraphQL */ `
  subscription OnCreatePartnerBrand {
    onCreatePartnerBrand {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdatePartnerBrand = /* GraphQL */ `
  subscription OnUpdatePartnerBrand {
    onUpdatePartnerBrand {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeletePartnerBrand = /* GraphQL */ `
  subscription OnDeletePartnerBrand {
    onDeletePartnerBrand {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreatePartnerWorkout = /* GraphQL */ `
  subscription OnCreatePartnerWorkout {
    onCreatePartnerWorkout {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdatePartnerWorkout = /* GraphQL */ `
  subscription OnUpdatePartnerWorkout {
    onUpdatePartnerWorkout {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeletePartnerWorkout = /* GraphQL */ `
  subscription OnDeletePartnerWorkout {
    onDeletePartnerWorkout {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
