/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  email: string,
  name: string,
  roles?: Array< UserRole > | null,
  isAdmin?: boolean | null,
  isSuperAdmin?: boolean | null,
};

export enum UserRole {
  ATHLETICS = "ATHLETICS",
  PROD1 = "PROD1",
  PROD2 = "PROD2",
  FILM = "FILM",
  PARTNER = "PARTNER",
}


export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  roles?: ModelUserRoleListInput | null,
  isAdmin?: ModelBooleanInput | null,
  isSuperAdmin?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelUserRoleListInput = {
  eq?: Array< UserRole | null > | null,
  ne?: Array< UserRole | null > | null,
  contains?: UserRole | null,
  notContains?: UserRole | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  name: string,
  roles?: Array< UserRole > | null,
  isAdmin?: boolean | null,
  isSuperAdmin?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  name?: string | null,
  roles?: Array< UserRole > | null,
  isAdmin?: boolean | null,
  isSuperAdmin?: boolean | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateQpointInput = {
  id?: string | null,
  code: string,
  label: string,
  audio: string,
};

export type ModelQpointConditionInput = {
  code?: ModelStringInput | null,
  label?: ModelStringInput | null,
  audio?: ModelStringInput | null,
  and?: Array< ModelQpointConditionInput | null > | null,
  or?: Array< ModelQpointConditionInput | null > | null,
  not?: ModelQpointConditionInput | null,
};

export type Qpoint = {
  __typename: "Qpoint",
  id: string,
  code: string,
  label: string,
  audio: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateQpointInput = {
  id: string,
  code?: string | null,
  label?: string | null,
  audio?: string | null,
};

export type DeleteQpointInput = {
  id: string,
};

export type CreateBrandInput = {
  id?: string | null,
  name: string,
  category: CategoryType,
  productMovementSetup?: Array< BrandProductMovementSetupInput > | null,
  trainer?: BrandTrainerInput | null,
  workoutSetup?: Array< BrandWorkoutSetupInput > | null,
  logoFile?: S3ObjectInput | null,
  iconFile?: S3ObjectInput | null,
  excelTemplateFile?: S3ObjectInput | null,
  longWarmupVideoFile?: S3ObjectInput | null,
  active: boolean,
};

export enum CategoryType {
  CARDIO = "CARDIO",
  FUSION = "FUSION",
  RESISTANCE = "RESISTANCE",
  RECOVER = "RECOVER",
  SPIN = "SPIN",
  ROW = "ROW",
  PARTNER = "PARTNER",
}


export type BrandProductMovementSetupInput = {
  productType: ProductType,
  movementTypes?: Array< MovementType > | null,
};

export enum ProductType {
  SIGNATURE_PRO = "SIGNATURE_PRO",
  SIGNATURE_PLUS = "SIGNATURE_PLUS",
  SIGNATURE = "SIGNATURE",
  HERO_PRO = "HERO_PRO",
  HERO_PLUS = "HERO_PLUS",
  HERO = "HERO",
  SIGNATURE_SMOOTH = "SIGNATURE_SMOOTH",
}


export enum MovementType {
  ABS_CORE = "ABS_CORE",
  AGILITY = "AGILITY",
  BREATHING = "BREATHING",
  BODYWEIGHT = "BODYWEIGHT",
  CARDIO_BODYWEIGHT = "CARDIO_BODYWEIGHT",
  CARDIO_EQUIPMENT = "CARDIO_EQUIPMENT",
  CARDIO_MACHINE = "CARDIO_MACHINE",
  COOLDOWN = "COOLDOWN",
  CONTENT = "CONTENT",
  COMBAT = "COMBAT",
  DYNAMIC_ROM = "DYNAMIC_ROM",
  FLEXIBILITY_STRETCH = "FLEXIBILITY_STRETCH",
  FUNCTIONAL_CARDIO = "FUNCTIONAL_CARDIO",
  FUNCTIONAL_RESISTANCE = "FUNCTIONAL_RESISTANCE",
  FUNCTIONAL_STRENGTH = "FUNCTIONAL_STRENGTH",
  ISOLATION = "ISOLATION",
  LOWER_PULL = "LOWER_PULL",
  LOWER_PUSH = "LOWER_PUSH",
  PLYOMETRIC = "PLYOMETRIC",
  UPPER_PULL = "UPPER_PULL",
  UPPER_PUSH = "UPPER_PUSH",
  WARMUP = "WARMUP",
  WARMUP_LONG = "WARMUP_LONG",
}


export type BrandTrainerInput = {
  name: string,
  inits: string,
};

export type BrandWorkoutSetupInput = {
  type: BrandWorkoutSetupType,
  setup?: Array< BrandWorkoutSetupExerciseInput > | null,
};

export enum BrandWorkoutSetupType {
  COOLDOWN = "COOLDOWN",
  WARMUP = "WARMUP",
  WARMUP_LONG = "WARMUP_LONG",
}


export type BrandWorkoutSetupExerciseInput = {
  exerciseId: string,
  movementType: MovementType,
  duration: number,
};

export type S3ObjectInput = {
  key: string,
  name: string,
  mimetype: string,
};

export type ModelBrandConditionInput = {
  name?: ModelStringInput | null,
  category?: ModelCategoryTypeInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type ModelCategoryTypeInput = {
  eq?: CategoryType | null,
  ne?: CategoryType | null,
};

export type Brand = {
  __typename: "Brand",
  id: string,
  name: string,
  category: CategoryType,
  productMovementSetup?:  Array<BrandProductMovementSetup > | null,
  trainer?: BrandTrainer | null,
  workoutSetup?:  Array<BrandWorkoutSetup > | null,
  logoFile?: S3Object | null,
  iconFile?: S3Object | null,
  excelTemplateFile?: S3Object | null,
  longWarmupVideoFile?: S3Object | null,
  active: boolean,
  createdAt: string,
  updatedAt: string,
};

export type BrandProductMovementSetup = {
  __typename: "BrandProductMovementSetup",
  productType: ProductType,
  movementTypes?: Array< MovementType > | null,
};

export type BrandTrainer = {
  __typename: "BrandTrainer",
  name: string,
  inits: string,
};

export type BrandWorkoutSetup = {
  __typename: "BrandWorkoutSetup",
  type: BrandWorkoutSetupType,
  setup?:  Array<BrandWorkoutSetupExercise > | null,
};

export type BrandWorkoutSetupExercise = {
  __typename: "BrandWorkoutSetupExercise",
  exerciseId: string,
  movementType: MovementType,
  duration: number,
};

export type S3Object = {
  __typename: "S3Object",
  key: string,
  name: string,
  mimetype: string,
};

export type UpdateBrandInput = {
  id: string,
  name?: string | null,
  category?: CategoryType | null,
  productMovementSetup?: Array< BrandProductMovementSetupInput > | null,
  trainer?: BrandTrainerInput | null,
  workoutSetup?: Array< BrandWorkoutSetupInput > | null,
  logoFile?: S3ObjectInput | null,
  iconFile?: S3ObjectInput | null,
  excelTemplateFile?: S3ObjectInput | null,
  longWarmupVideoFile?: S3ObjectInput | null,
  active?: boolean | null,
};

export type DeleteBrandInput = {
  id: string,
};

export type CreateExerciseInput = {
  id?: string | null,
  name: string,
  code?: string | null,
  lastUsed?: string | null,
  equipmentTypes?: Array< EquipmentType > | null,
  movementTypes?: Array< MovementType > | null,
  difficulty?: ExerciseDifficulty | null,
  brandSetup: Array< ExerciseBrandSetupInput >,
  qpoints?: Array< string > | null,
  productTypes?: Array< ProductType > | null,
  searchName: string,
  searchBrandList?: Array< string > | null,
  searchStatusList?: Array< string > | null,
  searchBrandStatusList?: Array< string > | null,
  searchWorkoutList?: Array< string > | null,
  searchPartnerWorkoutList?: Array< string > | null,
};

export enum EquipmentType {
  ACTIVATION_BAND = "ACTIVATION_BAND",
  AGILITY_LADDER = "AGILITY_LADDER",
  BALANCE_TRAINER = "BALANCE_TRAINER",
  BIKE = "BIKE",
  BODYWEIGHT = "BODYWEIGHT",
  CONE = "CONE",
  DEADBALL = "DEADBALL",
  DUMBBELL = "DUMBBELL",
  HURDLES = "HURDLES",
  KETTLEBELL = "KETTLEBELL",
  POWERBAND = "POWERBAND",
  REVO_BAR = "REVO_BAR",
  ROWING_MACHINE = "ROWING_MACHINE",
  SANDBAG = "SANDBAG",
  SKIPPING_ROPE = "SKIPPING_ROPE",
  SLIDES = "SLIDES",
  STEP_TRAINER = "STEP_TRAINER",
  SUSPENSION_TRAINER = "SUSPENSION_TRAINER",
  SWISS_BALL = "SWISS_BALL",
  WAR_BALL = "WAR_BALL",
}


export enum ExerciseDifficulty {
  EASY = "EASY",
  DIFFICULT = "DIFFICULT",
}


export type ExerciseBrandSetupInput = {
  brandId: string,
  status?: ExerciseVideoStatusType | null,
  rawFile?: S3ObjectInput | null,
  rawFileInStorage?: boolean | null,
  processedFile?: S3ObjectInput | null,
  fileRef?: string | null,
  filmDate?: string | null,
  notes?: string | null,
  lastUsed?: string | null,
  amountUsed?: number | null,
  isGuide?: boolean | null,
  isLongLoop?: boolean | null,
  prodTeamNotes?: string | null,
};

export enum ExerciseVideoStatusType {
  READY_TO_FILM = "READY_TO_FILM",
  READY_TO_UPLOAD = "READY_TO_UPLOAD",
  READY_TO_EDIT = "READY_TO_EDIT",
  READY_TO_APPROVE = "READY_TO_APPROVE",
  APPROVED = "APPROVED",
  REJECTED_REFILM = "REJECTED_REFILM",
  REJECTED_RELOOP = "REJECTED_RELOOP",
}


export type ModelExerciseConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  lastUsed?: ModelStringInput | null,
  equipmentTypes?: ModelEquipmentTypeListInput | null,
  movementTypes?: ModelMovementTypeListInput | null,
  difficulty?: ModelExerciseDifficultyInput | null,
  qpoints?: ModelIDInput | null,
  productTypes?: ModelProductTypeListInput | null,
  searchName?: ModelStringInput | null,
  searchBrandList?: ModelIDInput | null,
  searchStatusList?: ModelIDInput | null,
  searchBrandStatusList?: ModelStringInput | null,
  searchWorkoutList?: ModelIDInput | null,
  searchPartnerWorkoutList?: ModelIDInput | null,
  and?: Array< ModelExerciseConditionInput | null > | null,
  or?: Array< ModelExerciseConditionInput | null > | null,
  not?: ModelExerciseConditionInput | null,
};

export type ModelEquipmentTypeListInput = {
  eq?: Array< EquipmentType | null > | null,
  ne?: Array< EquipmentType | null > | null,
  contains?: EquipmentType | null,
  notContains?: EquipmentType | null,
};

export type ModelMovementTypeListInput = {
  eq?: Array< MovementType | null > | null,
  ne?: Array< MovementType | null > | null,
  contains?: MovementType | null,
  notContains?: MovementType | null,
};

export type ModelExerciseDifficultyInput = {
  eq?: ExerciseDifficulty | null,
  ne?: ExerciseDifficulty | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelProductTypeListInput = {
  eq?: Array< ProductType | null > | null,
  ne?: Array< ProductType | null > | null,
  contains?: ProductType | null,
  notContains?: ProductType | null,
};

export type Exercise = {
  __typename: "Exercise",
  id: string,
  name: string,
  code?: string | null,
  lastUsed?: string | null,
  equipmentTypes?: Array< EquipmentType > | null,
  movementTypes?: Array< MovementType > | null,
  difficulty?: ExerciseDifficulty | null,
  brandSetup:  Array<ExerciseBrandSetup >,
  qpoints?: Array< string > | null,
  productTypes?: Array< ProductType > | null,
  searchName: string,
  searchBrandList?: Array< string > | null,
  searchStatusList?: Array< string > | null,
  searchBrandStatusList?: Array< string > | null,
  searchWorkoutList?: Array< string > | null,
  searchPartnerWorkoutList?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type ExerciseBrandSetup = {
  __typename: "ExerciseBrandSetup",
  brandId: string,
  status?: ExerciseVideoStatusType | null,
  rawFile?: S3Object | null,
  rawFileInStorage?: boolean | null,
  processedFile?: S3Object | null,
  fileRef?: string | null,
  filmDate?: string | null,
  notes?: string | null,
  lastUsed?: string | null,
  amountUsed?: number | null,
  isGuide?: boolean | null,
  isLongLoop?: boolean | null,
  prodTeamNotes?: string | null,
};

export type UpdateExerciseInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  lastUsed?: string | null,
  equipmentTypes?: Array< EquipmentType > | null,
  movementTypes?: Array< MovementType > | null,
  difficulty?: ExerciseDifficulty | null,
  brandSetup?: Array< ExerciseBrandSetupInput > | null,
  qpoints?: Array< string > | null,
  productTypes?: Array< ProductType > | null,
  searchName?: string | null,
  searchBrandList?: Array< string > | null,
  searchStatusList?: Array< string > | null,
  searchBrandStatusList?: Array< string > | null,
  searchWorkoutList?: Array< string > | null,
  searchPartnerWorkoutList?: Array< string > | null,
};

export type DeleteExerciseInput = {
  id: string,
};

export type CreateWorkoutInput = {
  id?: string | null,
  sequence: number,
  workoutBrandId: string,
  productTypeWorkouts?: Array< WorkoutBrandProductTypeInput > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
};

export type WorkoutBrandProductTypeInput = {
  productType: ProductType,
  isInherited?: boolean | null,
  isDisabled?: boolean | null,
  exerciseSetup?: Array< WorkoutBrandProductTypeExerciseInput > | null,
  productionFile?: S3ObjectInput | null,
  status?: WorkoutVideoStatusType | null,
  jwPlayer?: string | null,
};

export type WorkoutBrandProductTypeExerciseInput = {
  movementType: MovementType,
  exerciseId?: string | null,
};

export enum WorkoutVideoStatusType {
  NOT_READY = "NOT_READY",
  READY_TO_EDIT = "READY_TO_EDIT",
  READY_TO_APPROVE = "READY_TO_APPROVE",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  INCOMPLETE = "INCOMPLETE",
  READY_TO_FILM = "READY_TO_FILM",
}


export type ModelWorkoutConditionInput = {
  sequence?: ModelIntInput | null,
  workoutBrandId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  searchExerciseList?: ModelIDInput | null,
  and?: Array< ModelWorkoutConditionInput | null > | null,
  or?: Array< ModelWorkoutConditionInput | null > | null,
  not?: ModelWorkoutConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Workout = {
  __typename: "Workout",
  id: string,
  sequence: number,
  workoutBrandId: string,
  productTypeWorkouts?:  Array<WorkoutBrandProductType > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  brand?: Brand | null,
};

export type WorkoutBrandProductType = {
  __typename: "WorkoutBrandProductType",
  productType: ProductType,
  isInherited?: boolean | null,
  isDisabled?: boolean | null,
  exerciseSetup?:  Array<WorkoutBrandProductTypeExercise > | null,
  productionFile?: S3Object | null,
  status?: WorkoutVideoStatusType | null,
  jwPlayer?: string | null,
};

export type WorkoutBrandProductTypeExercise = {
  __typename: "WorkoutBrandProductTypeExercise",
  movementType: MovementType,
  exerciseId?: string | null,
};

export type UpdateWorkoutInput = {
  id: string,
  sequence?: number | null,
  workoutBrandId?: string | null,
  productTypeWorkouts?: Array< WorkoutBrandProductTypeInput > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
};

export type DeleteWorkoutInput = {
  id: string,
};

export type CreateBulkOpsInput = {
  id?: string | null,
  type: BulkOpsType,
  success?: Array< string > | null,
  error?: Array< string > | null,
  isDeleted: boolean,
};

export enum BulkOpsType {
  IMPORT_RAW_VIDEOS = "IMPORT_RAW_VIDEOS",
  IMPORT_PROCESSED_VIDEOS = "IMPORT_PROCESSED_VIDEOS",
  EXPORT_PROCESSED_VIDEOS = "EXPORT_PROCESSED_VIDEOS",
  IMPORT_PROCESSED_WORKOUT_VIDEOS = "IMPORT_PROCESSED_WORKOUT_VIDEOS",
}


export type ModelBulkOpsConditionInput = {
  type?: ModelBulkOpsTypeInput | null,
  success?: ModelStringInput | null,
  error?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelBulkOpsConditionInput | null > | null,
  or?: Array< ModelBulkOpsConditionInput | null > | null,
  not?: ModelBulkOpsConditionInput | null,
};

export type ModelBulkOpsTypeInput = {
  eq?: BulkOpsType | null,
  ne?: BulkOpsType | null,
};

export type BulkOps = {
  __typename: "BulkOps",
  id: string,
  type: BulkOpsType,
  success?: Array< string > | null,
  error?: Array< string > | null,
  isDeleted: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBulkOpsInput = {
  id: string,
  type?: BulkOpsType | null,
  success?: Array< string > | null,
  error?: Array< string > | null,
  isDeleted?: boolean | null,
};

export type DeleteBulkOpsInput = {
  id: string,
};

export type CreateRawVideoExportLogInput = {
  id?: string | null,
  brandList?: Array< string > | null,
  from?: number | null,
  to?: number | null,
  compiled?: Array< RawVideoExportLogVideoInput > | null,
  uploadPath?: string | null,
  emailSetup?: RawVideoExportLogEmailSetupInput | null,
};

export type RawVideoExportLogVideoInput = {
  exerciseId: string,
  brandId: string,
};

export type RawVideoExportLogEmailSetupInput = {
  email: string,
  name: string,
};

export type ModelRawVideoExportLogConditionInput = {
  brandList?: ModelIDInput | null,
  from?: ModelIntInput | null,
  to?: ModelIntInput | null,
  uploadPath?: ModelStringInput | null,
  and?: Array< ModelRawVideoExportLogConditionInput | null > | null,
  or?: Array< ModelRawVideoExportLogConditionInput | null > | null,
  not?: ModelRawVideoExportLogConditionInput | null,
};

export type RawVideoExportLog = {
  __typename: "RawVideoExportLog",
  id: string,
  brandList?: Array< string > | null,
  from?: number | null,
  to?: number | null,
  compiled?:  Array<RawVideoExportLogVideo > | null,
  uploadPath?: string | null,
  emailSetup?: RawVideoExportLogEmailSetup | null,
  createdAt: string,
  updatedAt: string,
};

export type RawVideoExportLogVideo = {
  __typename: "RawVideoExportLogVideo",
  exerciseId: string,
  brandId: string,
};

export type RawVideoExportLogEmailSetup = {
  __typename: "RawVideoExportLogEmailSetup",
  email: string,
  name: string,
};

export type UpdateRawVideoExportLogInput = {
  id: string,
  brandList?: Array< string > | null,
  from?: number | null,
  to?: number | null,
  compiled?: Array< RawVideoExportLogVideoInput > | null,
  uploadPath?: string | null,
  emailSetup?: RawVideoExportLogEmailSetupInput | null,
};

export type DeleteRawVideoExportLogInput = {
  id: string,
};

export type CreatePartnerInput = {
  id?: string | null,
  name?: string | null,
  variations?: Array< string > | null,
};

export type ModelPartnerConditionInput = {
  name?: ModelStringInput | null,
  variations?: ModelStringInput | null,
  and?: Array< ModelPartnerConditionInput | null > | null,
  or?: Array< ModelPartnerConditionInput | null > | null,
  not?: ModelPartnerConditionInput | null,
};

export type Partner = {
  __typename: "Partner",
  id: string,
  name?: string | null,
  variations?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePartnerInput = {
  id: string,
  name?: string | null,
  variations?: Array< string > | null,
};

export type DeletePartnerInput = {
  id: string,
};

export type CreatePartnerBrandInput = {
  id?: string | null,
  brandPartnerId: string,
  name: string,
  category: CategoryType,
  variationMovementSetup?: Array< PartnerBrandVariationMovementSetupInput > | null,
  trainer?: BrandTrainerInput | null,
  workoutSetup?: Array< BrandWorkoutSetupInput > | null,
  excelTemplateFile?: S3ObjectInput | null,
  longWarmupVideoFile?: S3ObjectInput | null,
  notes?: string | null,
  useTemplate?: string | null,
  active: boolean,
};

export type PartnerBrandVariationMovementSetupInput = {
  variation: string,
  movementTypes?: Array< MovementType > | null,
};

export type ModelPartnerBrandConditionInput = {
  brandPartnerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelCategoryTypeInput | null,
  notes?: ModelStringInput | null,
  useTemplate?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPartnerBrandConditionInput | null > | null,
  or?: Array< ModelPartnerBrandConditionInput | null > | null,
  not?: ModelPartnerBrandConditionInput | null,
};

export type PartnerBrand = {
  __typename: "PartnerBrand",
  id: string,
  brandPartnerId: string,
  name: string,
  category: CategoryType,
  variationMovementSetup?:  Array<PartnerBrandVariationMovementSetup > | null,
  trainer?: BrandTrainer | null,
  workoutSetup?:  Array<BrandWorkoutSetup > | null,
  excelTemplateFile?: S3Object | null,
  longWarmupVideoFile?: S3Object | null,
  notes?: string | null,
  useTemplate?: string | null,
  active: boolean,
  createdAt: string,
  updatedAt: string,
  partner?: Partner | null,
};

export type PartnerBrandVariationMovementSetup = {
  __typename: "PartnerBrandVariationMovementSetup",
  variation: string,
  movementTypes?: Array< MovementType > | null,
};

export type UpdatePartnerBrandInput = {
  id: string,
  brandPartnerId?: string | null,
  name?: string | null,
  category?: CategoryType | null,
  variationMovementSetup?: Array< PartnerBrandVariationMovementSetupInput > | null,
  trainer?: BrandTrainerInput | null,
  workoutSetup?: Array< BrandWorkoutSetupInput > | null,
  excelTemplateFile?: S3ObjectInput | null,
  longWarmupVideoFile?: S3ObjectInput | null,
  notes?: string | null,
  useTemplate?: string | null,
  active?: boolean | null,
};

export type DeletePartnerBrandInput = {
  id: string,
};

export type CreatePartnerWorkoutInput = {
  id?: string | null,
  sequence: number,
  workoutPartnerBrandId: string,
  variationWorkouts?: Array< PartnerWorkoutBrandVariationInput > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
};

export type PartnerWorkoutBrandVariationInput = {
  variation: string,
  isDisabled?: boolean | null,
  exerciseSetup?: Array< PartnerWorkoutBrandVariationExerciseInput > | null,
  productionFile?: S3ObjectInput | null,
  status?: WorkoutVideoStatusType | null,
  jwPlayer?: string | null,
};

export type PartnerWorkoutBrandVariationExerciseInput = {
  movementType: MovementType,
  exerciseId?: string | null,
};

export type ModelPartnerWorkoutConditionInput = {
  sequence?: ModelIntInput | null,
  workoutPartnerBrandId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  searchExerciseList?: ModelIDInput | null,
  and?: Array< ModelPartnerWorkoutConditionInput | null > | null,
  or?: Array< ModelPartnerWorkoutConditionInput | null > | null,
  not?: ModelPartnerWorkoutConditionInput | null,
};

export type PartnerWorkout = {
  __typename: "PartnerWorkout",
  id: string,
  sequence: number,
  workoutPartnerBrandId: string,
  variationWorkouts?:  Array<PartnerWorkoutBrandVariation > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  partnerBrand?: PartnerBrand | null,
};

export type PartnerWorkoutBrandVariation = {
  __typename: "PartnerWorkoutBrandVariation",
  variation: string,
  isDisabled?: boolean | null,
  exerciseSetup?:  Array<PartnerWorkoutBrandVariationExercise > | null,
  productionFile?: S3Object | null,
  status?: WorkoutVideoStatusType | null,
  jwPlayer?: string | null,
};

export type PartnerWorkoutBrandVariationExercise = {
  __typename: "PartnerWorkoutBrandVariationExercise",
  movementType: MovementType,
  exerciseId?: string | null,
};

export type UpdatePartnerWorkoutInput = {
  id: string,
  sequence?: number | null,
  workoutPartnerBrandId?: string | null,
  variationWorkouts?: Array< PartnerWorkoutBrandVariationInput > | null,
  isDisabled?: boolean | null,
  searchExerciseList?: Array< string > | null,
};

export type DeletePartnerWorkoutInput = {
  id: string,
};

export type GenerateIdRtn = {
  __typename: "GenerateIdRtn",
  id?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  roles?: ModelUserRoleListInput | null,
  isAdmin?: ModelBooleanInput | null,
  isSuperAdmin?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelQpointFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  label?: ModelStringInput | null,
  audio?: ModelStringInput | null,
  and?: Array< ModelQpointFilterInput | null > | null,
  or?: Array< ModelQpointFilterInput | null > | null,
  not?: ModelQpointFilterInput | null,
};

export type ModelQpointConnection = {
  __typename: "ModelQpointConnection",
  items:  Array<Qpoint >,
  nextToken?: string | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelCategoryTypeInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand >,
  nextToken?: string | null,
};

export type ModelExerciseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  lastUsed?: ModelStringInput | null,
  equipmentTypes?: ModelEquipmentTypeListInput | null,
  movementTypes?: ModelMovementTypeListInput | null,
  difficulty?: ModelExerciseDifficultyInput | null,
  qpoints?: ModelIDInput | null,
  productTypes?: ModelProductTypeListInput | null,
  searchName?: ModelStringInput | null,
  searchBrandList?: ModelIDInput | null,
  searchStatusList?: ModelIDInput | null,
  searchBrandStatusList?: ModelStringInput | null,
  searchWorkoutList?: ModelIDInput | null,
  searchPartnerWorkoutList?: ModelIDInput | null,
  and?: Array< ModelExerciseFilterInput | null > | null,
  or?: Array< ModelExerciseFilterInput | null > | null,
  not?: ModelExerciseFilterInput | null,
};

export type ModelExerciseConnection = {
  __typename: "ModelExerciseConnection",
  items:  Array<Exercise >,
  nextToken?: string | null,
};

export type ModelWorkoutFilterInput = {
  id?: ModelIDInput | null,
  sequence?: ModelIntInput | null,
  workoutBrandId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  searchExerciseList?: ModelIDInput | null,
  and?: Array< ModelWorkoutFilterInput | null > | null,
  or?: Array< ModelWorkoutFilterInput | null > | null,
  not?: ModelWorkoutFilterInput | null,
};

export type ModelWorkoutConnection = {
  __typename: "ModelWorkoutConnection",
  items:  Array<Workout >,
  nextToken?: string | null,
};

export type ModelBulkOpsFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBulkOpsTypeInput | null,
  success?: ModelStringInput | null,
  error?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelBulkOpsFilterInput | null > | null,
  or?: Array< ModelBulkOpsFilterInput | null > | null,
  not?: ModelBulkOpsFilterInput | null,
};

export type ModelBulkOpsConnection = {
  __typename: "ModelBulkOpsConnection",
  items:  Array<BulkOps >,
  nextToken?: string | null,
};

export type ModelRawVideoExportLogFilterInput = {
  id?: ModelIDInput | null,
  brandList?: ModelIDInput | null,
  from?: ModelIntInput | null,
  to?: ModelIntInput | null,
  uploadPath?: ModelStringInput | null,
  and?: Array< ModelRawVideoExportLogFilterInput | null > | null,
  or?: Array< ModelRawVideoExportLogFilterInput | null > | null,
  not?: ModelRawVideoExportLogFilterInput | null,
};

export type ModelRawVideoExportLogConnection = {
  __typename: "ModelRawVideoExportLogConnection",
  items:  Array<RawVideoExportLog >,
  nextToken?: string | null,
};

export type ModelPartnerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  variations?: ModelStringInput | null,
  and?: Array< ModelPartnerFilterInput | null > | null,
  or?: Array< ModelPartnerFilterInput | null > | null,
  not?: ModelPartnerFilterInput | null,
};

export type ModelPartnerConnection = {
  __typename: "ModelPartnerConnection",
  items:  Array<Partner >,
  nextToken?: string | null,
};

export type ModelPartnerBrandFilterInput = {
  id?: ModelIDInput | null,
  brandPartnerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelCategoryTypeInput | null,
  notes?: ModelStringInput | null,
  useTemplate?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelPartnerBrandFilterInput | null > | null,
  or?: Array< ModelPartnerBrandFilterInput | null > | null,
  not?: ModelPartnerBrandFilterInput | null,
};

export type ModelPartnerBrandConnection = {
  __typename: "ModelPartnerBrandConnection",
  items:  Array<PartnerBrand >,
  nextToken?: string | null,
};

export type ModelPartnerWorkoutFilterInput = {
  id?: ModelIDInput | null,
  sequence?: ModelIntInput | null,
  workoutPartnerBrandId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  searchExerciseList?: ModelIDInput | null,
  and?: Array< ModelPartnerWorkoutFilterInput | null > | null,
  or?: Array< ModelPartnerWorkoutFilterInput | null > | null,
  not?: ModelPartnerWorkoutFilterInput | null,
};

export type ModelPartnerWorkoutConnection = {
  __typename: "ModelPartnerWorkoutConnection",
  items:  Array<PartnerWorkout >,
  nextToken?: string | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQpointMutationVariables = {
  input: CreateQpointInput,
  condition?: ModelQpointConditionInput | null,
};

export type CreateQpointMutation = {
  createQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQpointMutationVariables = {
  input: UpdateQpointInput,
  condition?: ModelQpointConditionInput | null,
};

export type UpdateQpointMutation = {
  updateQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQpointMutationVariables = {
  input: DeleteQpointInput,
  condition?: ModelQpointConditionInput | null,
};

export type DeleteQpointMutation = {
  deleteQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateExerciseMutationVariables = {
  input: CreateExerciseInput,
  condition?: ModelExerciseConditionInput | null,
};

export type CreateExerciseMutation = {
  createExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateExerciseMutationVariables = {
  input: UpdateExerciseInput,
  condition?: ModelExerciseConditionInput | null,
};

export type UpdateExerciseMutation = {
  updateExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteExerciseMutationVariables = {
  input: DeleteExerciseInput,
  condition?: ModelExerciseConditionInput | null,
};

export type DeleteExerciseMutation = {
  deleteExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkoutMutationVariables = {
  input: CreateWorkoutInput,
  condition?: ModelWorkoutConditionInput | null,
};

export type CreateWorkoutMutation = {
  createWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateWorkoutMutationVariables = {
  input: UpdateWorkoutInput,
  condition?: ModelWorkoutConditionInput | null,
};

export type UpdateWorkoutMutation = {
  updateWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteWorkoutMutationVariables = {
  input: DeleteWorkoutInput,
  condition?: ModelWorkoutConditionInput | null,
};

export type DeleteWorkoutMutation = {
  deleteWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateBulkOpsMutationVariables = {
  input: CreateBulkOpsInput,
  condition?: ModelBulkOpsConditionInput | null,
};

export type CreateBulkOpsMutation = {
  createBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBulkOpsMutationVariables = {
  input: UpdateBulkOpsInput,
  condition?: ModelBulkOpsConditionInput | null,
};

export type UpdateBulkOpsMutation = {
  updateBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBulkOpsMutationVariables = {
  input: DeleteBulkOpsInput,
  condition?: ModelBulkOpsConditionInput | null,
};

export type DeleteBulkOpsMutation = {
  deleteBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRawVideoExportLogMutationVariables = {
  input: CreateRawVideoExportLogInput,
  condition?: ModelRawVideoExportLogConditionInput | null,
};

export type CreateRawVideoExportLogMutation = {
  createRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRawVideoExportLogMutationVariables = {
  input: UpdateRawVideoExportLogInput,
  condition?: ModelRawVideoExportLogConditionInput | null,
};

export type UpdateRawVideoExportLogMutation = {
  updateRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRawVideoExportLogMutationVariables = {
  input: DeleteRawVideoExportLogInput,
  condition?: ModelRawVideoExportLogConditionInput | null,
};

export type DeleteRawVideoExportLogMutation = {
  deleteRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePartnerMutationVariables = {
  input: CreatePartnerInput,
  condition?: ModelPartnerConditionInput | null,
};

export type CreatePartnerMutation = {
  createPartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePartnerMutationVariables = {
  input: UpdatePartnerInput,
  condition?: ModelPartnerConditionInput | null,
};

export type UpdatePartnerMutation = {
  updatePartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePartnerMutationVariables = {
  input: DeletePartnerInput,
  condition?: ModelPartnerConditionInput | null,
};

export type DeletePartnerMutation = {
  deletePartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePartnerBrandMutationVariables = {
  input: CreatePartnerBrandInput,
  condition?: ModelPartnerBrandConditionInput | null,
};

export type CreatePartnerBrandMutation = {
  createPartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePartnerBrandMutationVariables = {
  input: UpdatePartnerBrandInput,
  condition?: ModelPartnerBrandConditionInput | null,
};

export type UpdatePartnerBrandMutation = {
  updatePartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePartnerBrandMutationVariables = {
  input: DeletePartnerBrandInput,
  condition?: ModelPartnerBrandConditionInput | null,
};

export type DeletePartnerBrandMutation = {
  deletePartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreatePartnerWorkoutMutationVariables = {
  input: CreatePartnerWorkoutInput,
  condition?: ModelPartnerWorkoutConditionInput | null,
};

export type CreatePartnerWorkoutMutation = {
  createPartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdatePartnerWorkoutMutationVariables = {
  input: UpdatePartnerWorkoutInput,
  condition?: ModelPartnerWorkoutConditionInput | null,
};

export type UpdatePartnerWorkoutMutation = {
  updatePartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeletePartnerWorkoutMutationVariables = {
  input: DeletePartnerWorkoutInput,
  condition?: ModelPartnerWorkoutConditionInput | null,
};

export type DeletePartnerWorkoutMutation = {
  deletePartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type GenerateIdQuery = {
  generateId?:  {
    __typename: "GenerateIdRtn",
    id?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      name: string,
      roles?: Array< UserRole > | null,
      isAdmin?: boolean | null,
      isSuperAdmin?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByEmailQuery = {
  listUserByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      name: string,
      roles?: Array< UserRole > | null,
      isAdmin?: boolean | null,
      isSuperAdmin?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetQpointQueryVariables = {
  id: string,
};

export type GetQpointQuery = {
  getQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQpointsQueryVariables = {
  filter?: ModelQpointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQpointsQuery = {
  listQpoints?:  {
    __typename: "ModelQpointConnection",
    items:  Array< {
      __typename: "Qpoint",
      id: string,
      code: string,
      label: string,
      audio: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetExerciseQueryVariables = {
  id: string,
};

export type GetExerciseQuery = {
  getExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListExercisesQueryVariables = {
  filter?: ModelExerciseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExercisesQuery = {
  listExercises?:  {
    __typename: "ModelExerciseConnection",
    items:  Array< {
      __typename: "Exercise",
      id: string,
      name: string,
      code?: string | null,
      lastUsed?: string | null,
      equipmentTypes?: Array< EquipmentType > | null,
      movementTypes?: Array< MovementType > | null,
      difficulty?: ExerciseDifficulty | null,
      brandSetup:  Array< {
        __typename: "ExerciseBrandSetup",
        brandId: string,
        status?: ExerciseVideoStatusType | null,
        rawFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        rawFileInStorage?: boolean | null,
        processedFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        fileRef?: string | null,
        filmDate?: string | null,
        notes?: string | null,
        lastUsed?: string | null,
        amountUsed?: number | null,
        isGuide?: boolean | null,
        isLongLoop?: boolean | null,
        prodTeamNotes?: string | null,
      } >,
      qpoints?: Array< string > | null,
      productTypes?: Array< ProductType > | null,
      searchName: string,
      searchBrandList?: Array< string > | null,
      searchStatusList?: Array< string > | null,
      searchBrandStatusList?: Array< string > | null,
      searchWorkoutList?: Array< string > | null,
      searchPartnerWorkoutList?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkoutQueryVariables = {
  id: string,
};

export type GetWorkoutQuery = {
  getWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListWorkoutsQueryVariables = {
  filter?: ModelWorkoutFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkoutsQuery = {
  listWorkouts?:  {
    __typename: "ModelWorkoutConnection",
    items:  Array< {
      __typename: "Workout",
      id: string,
      sequence: number,
      workoutBrandId: string,
      productTypeWorkouts?:  Array< {
        __typename: "WorkoutBrandProductType",
        productType: ProductType,
        isInherited?: boolean | null,
        isDisabled?: boolean | null,
        exerciseSetup?:  Array< {
          __typename: "WorkoutBrandProductTypeExercise",
          movementType: MovementType,
          exerciseId?: string | null,
        } > | null,
        productionFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        status?: WorkoutVideoStatusType | null,
        jwPlayer?: string | null,
      } > | null,
      isDisabled?: boolean | null,
      searchExerciseList?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        name: string,
        category: CategoryType,
        productMovementSetup?:  Array< {
          __typename: "BrandProductMovementSetup",
          productType: ProductType,
          movementTypes?: Array< MovementType > | null,
        } > | null,
        trainer?:  {
          __typename: "BrandTrainer",
          name: string,
          inits: string,
        } | null,
        workoutSetup?:  Array< {
          __typename: "BrandWorkoutSetup",
          type: BrandWorkoutSetupType,
          setup?:  Array< {
            __typename: "BrandWorkoutSetupExercise",
            exerciseId: string,
            movementType: MovementType,
            duration: number,
          } > | null,
        } > | null,
        logoFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        iconFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        excelTemplateFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        longWarmupVideoFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        active: boolean,
        createdAt: string,
        updatedAt: string,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetBulkOpsQueryVariables = {
  id: string,
};

export type GetBulkOpsQuery = {
  getBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBulkOpssQueryVariables = {
  filter?: ModelBulkOpsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBulkOpssQuery = {
  listBulkOpss?:  {
    __typename: "ModelBulkOpsConnection",
    items:  Array< {
      __typename: "BulkOps",
      id: string,
      type: BulkOpsType,
      success?: Array< string > | null,
      error?: Array< string > | null,
      isDeleted: boolean,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetRawVideoExportLogQueryVariables = {
  id: string,
};

export type GetRawVideoExportLogQuery = {
  getRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRawVideoExportLogsQueryVariables = {
  filter?: ModelRawVideoExportLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRawVideoExportLogsQuery = {
  listRawVideoExportLogs?:  {
    __typename: "ModelRawVideoExportLogConnection",
    items:  Array< {
      __typename: "RawVideoExportLog",
      id: string,
      brandList?: Array< string > | null,
      from?: number | null,
      to?: number | null,
      compiled?:  Array< {
        __typename: "RawVideoExportLogVideo",
        exerciseId: string,
        brandId: string,
      } > | null,
      uploadPath?: string | null,
      emailSetup?:  {
        __typename: "RawVideoExportLogEmailSetup",
        email: string,
        name: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPartnerQueryVariables = {
  id: string,
};

export type GetPartnerQuery = {
  getPartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPartnersQueryVariables = {
  filter?: ModelPartnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartnersQuery = {
  listPartners?:  {
    __typename: "ModelPartnerConnection",
    items:  Array< {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPartnerBrandQueryVariables = {
  id: string,
};

export type GetPartnerBrandQuery = {
  getPartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPartnerBrandsQueryVariables = {
  filter?: ModelPartnerBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartnerBrandsQuery = {
  listPartnerBrands?:  {
    __typename: "ModelPartnerBrandConnection",
    items:  Array< {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPartnerWorkoutQueryVariables = {
  id: string,
};

export type GetPartnerWorkoutQuery = {
  getPartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListPartnerWorkoutsQueryVariables = {
  filter?: ModelPartnerWorkoutFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartnerWorkoutsQuery = {
  listPartnerWorkouts?:  {
    __typename: "ModelPartnerWorkoutConnection",
    items:  Array< {
      __typename: "PartnerWorkout",
      id: string,
      sequence: number,
      workoutPartnerBrandId: string,
      variationWorkouts?:  Array< {
        __typename: "PartnerWorkoutBrandVariation",
        variation: string,
        isDisabled?: boolean | null,
        exerciseSetup?:  Array< {
          __typename: "PartnerWorkoutBrandVariationExercise",
          movementType: MovementType,
          exerciseId?: string | null,
        } > | null,
        productionFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        status?: WorkoutVideoStatusType | null,
        jwPlayer?: string | null,
      } > | null,
      isDisabled?: boolean | null,
      searchExerciseList?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      partnerBrand?:  {
        __typename: "PartnerBrand",
        id: string,
        brandPartnerId: string,
        name: string,
        category: CategoryType,
        variationMovementSetup?:  Array< {
          __typename: "PartnerBrandVariationMovementSetup",
          variation: string,
          movementTypes?: Array< MovementType > | null,
        } > | null,
        trainer?:  {
          __typename: "BrandTrainer",
          name: string,
          inits: string,
        } | null,
        workoutSetup?:  Array< {
          __typename: "BrandWorkoutSetup",
          type: BrandWorkoutSetupType,
          setup?:  Array< {
            __typename: "BrandWorkoutSetupExercise",
            exerciseId: string,
            movementType: MovementType,
            duration: number,
          } > | null,
        } > | null,
        excelTemplateFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        longWarmupVideoFile?:  {
          __typename: "S3Object",
          key: string,
          name: string,
          mimetype: string,
        } | null,
        notes?: string | null,
        useTemplate?: string | null,
        active: boolean,
        createdAt: string,
        updatedAt: string,
        partner?:  {
          __typename: "Partner",
          id: string,
          name?: string | null,
          variations?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    name: string,
    roles?: Array< UserRole > | null,
    isAdmin?: boolean | null,
    isSuperAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQpointSubscription = {
  onCreateQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQpointSubscription = {
  onUpdateQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQpointSubscription = {
  onDeleteQpoint?:  {
    __typename: "Qpoint",
    id: string,
    code: string,
    label: string,
    audio: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    category: CategoryType,
    productMovementSetup?:  Array< {
      __typename: "BrandProductMovementSetup",
      productType: ProductType,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    logoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    iconFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExerciseSubscription = {
  onCreateExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateExerciseSubscription = {
  onUpdateExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteExerciseSubscription = {
  onDeleteExercise?:  {
    __typename: "Exercise",
    id: string,
    name: string,
    code?: string | null,
    lastUsed?: string | null,
    equipmentTypes?: Array< EquipmentType > | null,
    movementTypes?: Array< MovementType > | null,
    difficulty?: ExerciseDifficulty | null,
    brandSetup:  Array< {
      __typename: "ExerciseBrandSetup",
      brandId: string,
      status?: ExerciseVideoStatusType | null,
      rawFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      rawFileInStorage?: boolean | null,
      processedFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      fileRef?: string | null,
      filmDate?: string | null,
      notes?: string | null,
      lastUsed?: string | null,
      amountUsed?: number | null,
      isGuide?: boolean | null,
      isLongLoop?: boolean | null,
      prodTeamNotes?: string | null,
    } >,
    qpoints?: Array< string > | null,
    productTypes?: Array< ProductType > | null,
    searchName: string,
    searchBrandList?: Array< string > | null,
    searchStatusList?: Array< string > | null,
    searchBrandStatusList?: Array< string > | null,
    searchWorkoutList?: Array< string > | null,
    searchPartnerWorkoutList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkoutSubscription = {
  onCreateWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateWorkoutSubscription = {
  onUpdateWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteWorkoutSubscription = {
  onDeleteWorkout?:  {
    __typename: "Workout",
    id: string,
    sequence: number,
    workoutBrandId: string,
    productTypeWorkouts?:  Array< {
      __typename: "WorkoutBrandProductType",
      productType: ProductType,
      isInherited?: boolean | null,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "WorkoutBrandProductTypeExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      name: string,
      category: CategoryType,
      productMovementSetup?:  Array< {
        __typename: "BrandProductMovementSetup",
        productType: ProductType,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      logoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      iconFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateBulkOpsSubscription = {
  onCreateBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBulkOpsSubscription = {
  onUpdateBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBulkOpsSubscription = {
  onDeleteBulkOps?:  {
    __typename: "BulkOps",
    id: string,
    type: BulkOpsType,
    success?: Array< string > | null,
    error?: Array< string > | null,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRawVideoExportLogSubscription = {
  onCreateRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRawVideoExportLogSubscription = {
  onUpdateRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRawVideoExportLogSubscription = {
  onDeleteRawVideoExportLog?:  {
    __typename: "RawVideoExportLog",
    id: string,
    brandList?: Array< string > | null,
    from?: number | null,
    to?: number | null,
    compiled?:  Array< {
      __typename: "RawVideoExportLogVideo",
      exerciseId: string,
      brandId: string,
    } > | null,
    uploadPath?: string | null,
    emailSetup?:  {
      __typename: "RawVideoExportLogEmailSetup",
      email: string,
      name: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePartnerSubscription = {
  onCreatePartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePartnerSubscription = {
  onUpdatePartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePartnerSubscription = {
  onDeletePartner?:  {
    __typename: "Partner",
    id: string,
    name?: string | null,
    variations?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePartnerBrandSubscription = {
  onCreatePartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePartnerBrandSubscription = {
  onUpdatePartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePartnerBrandSubscription = {
  onDeletePartnerBrand?:  {
    __typename: "PartnerBrand",
    id: string,
    brandPartnerId: string,
    name: string,
    category: CategoryType,
    variationMovementSetup?:  Array< {
      __typename: "PartnerBrandVariationMovementSetup",
      variation: string,
      movementTypes?: Array< MovementType > | null,
    } > | null,
    trainer?:  {
      __typename: "BrandTrainer",
      name: string,
      inits: string,
    } | null,
    workoutSetup?:  Array< {
      __typename: "BrandWorkoutSetup",
      type: BrandWorkoutSetupType,
      setup?:  Array< {
        __typename: "BrandWorkoutSetupExercise",
        exerciseId: string,
        movementType: MovementType,
        duration: number,
      } > | null,
    } > | null,
    excelTemplateFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    longWarmupVideoFile?:  {
      __typename: "S3Object",
      key: string,
      name: string,
      mimetype: string,
    } | null,
    notes?: string | null,
    useTemplate?: string | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    partner?:  {
      __typename: "Partner",
      id: string,
      name?: string | null,
      variations?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreatePartnerWorkoutSubscription = {
  onCreatePartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePartnerWorkoutSubscription = {
  onUpdatePartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePartnerWorkoutSubscription = {
  onDeletePartnerWorkout?:  {
    __typename: "PartnerWorkout",
    id: string,
    sequence: number,
    workoutPartnerBrandId: string,
    variationWorkouts?:  Array< {
      __typename: "PartnerWorkoutBrandVariation",
      variation: string,
      isDisabled?: boolean | null,
      exerciseSetup?:  Array< {
        __typename: "PartnerWorkoutBrandVariationExercise",
        movementType: MovementType,
        exerciseId?: string | null,
      } > | null,
      productionFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      status?: WorkoutVideoStatusType | null,
      jwPlayer?: string | null,
    } > | null,
    isDisabled?: boolean | null,
    searchExerciseList?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    partnerBrand?:  {
      __typename: "PartnerBrand",
      id: string,
      brandPartnerId: string,
      name: string,
      category: CategoryType,
      variationMovementSetup?:  Array< {
        __typename: "PartnerBrandVariationMovementSetup",
        variation: string,
        movementTypes?: Array< MovementType > | null,
      } > | null,
      trainer?:  {
        __typename: "BrandTrainer",
        name: string,
        inits: string,
      } | null,
      workoutSetup?:  Array< {
        __typename: "BrandWorkoutSetup",
        type: BrandWorkoutSetupType,
        setup?:  Array< {
          __typename: "BrandWorkoutSetupExercise",
          exerciseId: string,
          movementType: MovementType,
          duration: number,
        } > | null,
      } > | null,
      excelTemplateFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      longWarmupVideoFile?:  {
        __typename: "S3Object",
        key: string,
        name: string,
        mimetype: string,
      } | null,
      notes?: string | null,
      useTemplate?: string | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      partner?:  {
        __typename: "Partner",
        id: string,
        name?: string | null,
        variations?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};
