import assert from 'assert';
import * as _ from 'lodash';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

/**
 *
 */
export enum DBOperationType {
  get = 'get',
  list = 'list',
  create = 'create',
  update = 'update',
  delete = 'delete',
}

/**
 *
 * @param operation
 * @param tableName
 */
export const getOpName = (operation: DBOperationType, tableName: string): string => {
  assert(_.isString(operation) && _.trim(operation) !== '');
  assert(_.isString(tableName) && _.trim(tableName) !== '');

  let opName: string = `${operation}${tableName}`;

  if (operation === 'list') {
    opName += 's';
  }

  return opName;
};

/**
 *
 * @param operation
 * @param tableName
 */
export const getOpFunc = (operation: DBOperationType, tableName: string): any => {
  assert(_.isString(operation) && _.trim(operation) !== '');
  assert(_.isString(tableName) && _.trim(tableName) !== '');

  const opName: string = getOpName(operation, tableName);

  if (['get', 'list'].some(x => operation.startsWith(x))) {
    return (queries as any)[opName];
  } else if (['create', 'update', 'delete'].some(x => operation.startsWith(x))) {
    return (mutations as any)[opName];
  } else {
    throw new Error('Unrecognised operation');
  }
};

/**
 *
 * @param operation
 * @param tableName
 */
export const getOpSetup = (operation: DBOperationType, tableName: string): { name: string; func: any } => {
  assert(_.isString(operation) && _.trim(operation) !== '');
  assert(_.isString(tableName) && _.trim(tableName) !== '');

  return {
    name: getOpName(operation, tableName),
    func: getOpFunc(operation, tableName),
  };
};
