import Vue from 'vue';
import VueRouter, { RouteConfig, Route, RouteRecord, NavigationGuardNext } from 'vue-router';
import { UserRole } from '@/constants';
import Layout from '@/layout/index.vue';
import EmptyRouteView from './components/EmptyRouteView.vue';
import AppStore from '@/store/modules/app';
import ExerciseStore from '@/store/modules/exercise';
import QpointStore from '@/store/modules/qpoint';
import BrandStore from '@/store/modules/brand';
import WorkoutStore from '@/store/modules/workout';
import UserStore from '@/store/modules/user';
import PartnerStore from '@/store/modules/partner';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';
import { UserModel, WorkoutDocument } from '@/models';

Vue.use(VueRouter);

const appRoutes: Record<string, RouteConfig[]> = {
  admin: [
    {
      path: 'bulkops',
      name: 'bulkops-list',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/BulkOpsList.vue'),
      meta: { title: 'List Bulk Operations' },
    },
    {
      path: 'integrity-report',
      name: 'integrity-report',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/IntegrityReport.vue'),
      meta: { title: 'Integrity Check' },
    },
  ],
  partners: [
    {
      path: 'partners',
      name: 'partner-list',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partners/PartnerList.vue'),
      meta: { title: 'Partners' },
    },
    {
      path: 'partners/create',
      name: 'partner-create',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partners/PartnerManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerStore.startNew();

        next();
      },
    },
    {
      path: 'partners/partner/:id',
      name: 'partner-update',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partners/PartnerManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerStore.loadById(to.params.id);

        next();
      },
    },
    {
      path: 'partnerbrands',
      name: 'partnerbrand-list',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerbrands/PartnerBrandList.vue'),
      meta: { title: 'Partner Brands' },
    },
    {
      path: 'partnerbrands/create/:partnerId',
      name: 'partnerbrand-create',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerbrands/PartnerBrandManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerBrandStore.startNew(to.params.partnerId);

        next();
      },
    },
    {
      path: 'partnerbrands/partnerbrand/:id',
      name: 'partnerbrand-update',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerbrands/PartnerBrandManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerBrandStore.loadById(to.params.id);

        next();
      },
    },
    {
      path: 'partnerworkouts',
      name: 'partnerworkout-brand-select',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerworkouts/PartnerWorkoutList.vue'),
      meta: { title: 'Partner Workouts' },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerBrandStore.loadAll();

        next();
      },
    },
    {
      path: 'partnerworkouts/:id',
      name: 'partnerworkout-list',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerworkouts/PartnerWorkoutList.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerBrandStore.loadAll();

        next();
      },
    },
    {
      path: 'partnerworkouts/create/:partnerBrandId',
      name: 'partnerworkout-create',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerworkouts/PartnerWorkoutManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await Promise.all([PartnerWorkoutStore.startNew(to.params.partnerBrandId), PartnerBrandStore.loadById(to.params.partnerBrandId)]);

        next();
      },
    },
    {
      path: 'partnerworkouts/partnerworkout/:id',
      name: 'partnerworkout-update',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/partnerworkouts/PartnerWorkoutManage.vue'),
      meta: { hidden: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        await PartnerWorkoutStore.loadById(to.params.id);

        if (!PartnerWorkoutStore.current) {
          throw new Error(`Partner Workout ${to.params.id} cannot be found`);
        }

        await PartnerBrandStore.loadById(PartnerWorkoutStore.current.workoutPartnerBrandId);

        next();
      },
    },
  ],
  workouts: [
    {
      path: 'workouts',
      component: EmptyRouteView,
      meta: {
        title: 'Workouts',
        userRoles: [UserRole.ATHLETICS, UserRole.PROD2],
      },
      redirect: '/workouts',
      children: [
        {
          path: '/',
          name: 'workout-main-dashboard',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutDashboard.vue'),
          meta: { title: 'Dashboard' },
          beforeEnter: async (to: any, from: any, next: any) => {
            await WorkoutStore.loadAll();

            next();
          },
        },
        {
          path: '/list/:id',
          name: 'workout-List',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutList.vue'),
          meta: { title: 'Workout List', hidden: true },
          beforeEnter: async (to: any, from: any, next: any) => {
            await WorkoutStore.loadByIdWithOptions(to.params.id);

            const workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;

            if (!workout) {
              throw new Error(`Workout ${to.params.id} cannot be found`);
            }

            next();
          },
        },
        {
          path: 'overview',
          name: 'workout-overview',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutOverview.vue'),
          meta: {
            title: 'Workout Overview',
            userRoles: [UserRole.ATHLETICS],
          },
          beforeEnter: async (to: any, from: any, next: any) => {
            await Promise.all([WorkoutStore.loadAll(), ExerciseStore.loadAll()]);

            next();
          },
        },
        {
          path: 'compare',
          name: 'workout-compare',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutCompare.vue'),
          meta: {
            title: 'Workout Comparison',
            userRoles: [UserRole.ATHLETICS],
          },
          beforeEnter: async (to: any, from: any, next: any) => {
            await WorkoutStore.loadAll();

            next();
          },
        },
        {
          path: 'create/:brandId',
          name: 'workout-create',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutManage.vue'),
          meta: {
            title: 'Create Workout',
            hidden: true,
            userRoles: [UserRole.ATHLETICS],
          },
          beforeEnter: async (to: any, from: any, next: any) => {
            await WorkoutStore.startNewWithOptions(to.params.brandId);

            next();
          },
        },
        {
          path: 'workout/:id',
          name: 'workout-update',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutManage.vue'),
          meta: { title: 'Update Workout', hidden: true },
          beforeEnter: async (to: any, from: any, next: any) => {
            await WorkoutStore.loadByIdWithOptions(to.params.id);

            const workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;

            if (!workout) {
              throw new Error(`Workout ${to.params.id} cannot be found`);
            }

            next();
          },
        },
        {
          path: ':id',
          name: 'workout-list',
          component: () => import(/* webpackChunkName: "workout" */ '@/views/workouts/WorkoutList.vue'),
          meta: { title: 'List Brand Workouts', hidden: true },
        },
      ],
    },
  ],

  exercises: [
    {
      path: 'exercises',
      component: EmptyRouteView,
      meta: { title: 'Exercises' },
      redirect: '/exercises',
      children: [
        {
          path: '/',
          name: 'exercise-list',
          component: () => import(/* webpackChunkName: "exercise" */ '@/views/exercises/ExerciseList.vue'),
          meta: { title: 'List Exercises' },
        },
        {
          path: 'create',
          name: 'exercise-create',
          component: () => import(/* webpackChunkName: "exercise" */ '@/views/exercises/ExerciseManage.vue'),
          meta: {
            title: 'Create Exercise',
            userRoles: [UserRole.ATHLETICS],
          },
          beforeEnter: async (to: any, from: any, next: any) => {
            await Promise.all([ExerciseStore.startNew(), QpointStore.loadAll()]);

            await ExerciseStore.startNew();

            next();
          },
        },
        {
          path: 'exercise/:id',
          name: 'exercise-update',
          component: () => import(/* webpackChunkName: "exercise" */ '@/views/exercises/ExerciseManage.vue'),
          beforeEnter: async (to: any, from: any, next: any) => {
            await Promise.all([ExerciseStore.loadById(to.params.id), QpointStore.loadAll()]);

            next();
          },
          meta: { hidden: true },
        },
      ],
    },
  ],

  metadata: [
    {
      path: 'metadata',
      component: EmptyRouteView,
      meta: {
        title: 'Metadata',
        userRoles: [UserRole.ATHLETICS, UserRole.PROD2, UserRole.FILM],
      },
      redirect: '/qpoints',
      children: [
        {
          path: 'qpoints',
          component: () => import(/* webpackChunkName: "meta" */ '@/views/meta/qpoints/QpointsList.vue'),
          meta: { title: 'List Q Points' },
        },
      ],
    },
  ],

  brands: [
    {
      path: 'brands',
      component: EmptyRouteView,
      meta: {
        title: 'Brands',
      },
      redirect: '/brands',
      children: [
        {
          path: '/',
          name: 'brand-list',
          component: () => import(/* webpackChunkName: "brands" */ '@/views/brands/BrandList.vue'),
          meta: { title: 'List Brands' },
        },
        {
          path: 'create',
          name: 'brand-create',
          component: () => import(/* webpackChunkName: "brands" */ '@/views/brands/BrandManage.vue'),
          meta: {
            title: 'Create Brand',
            userRoles: [UserRole.ATHLETICS],
          },
          beforeEnter: async (to: any, from: any, next: any) => {
            await BrandStore.startNew();

            next();
          },
        },
        {
          path: 'brand/:id',
          name: 'brand-update',
          component: () => import(/* webpackChunkName: "brands" */ '@/views/brands/BrandManage.vue'),
          beforeEnter: async (to: any, from: any, next: any) => {
            await BrandStore.loadById(to.params.id);

            next();
          },
          meta: { hidden: true },
        },
      ],
    },
  ],
};

/**
 * Get the default routes
 *
 * Function will return an array of default routes that all menu items receive
 *
 * @access public
 * @return {RouteConfig[]} The default routes
 */
const getDefaultRoutes = (): RouteConfig[] => [
  {
    path: 'exercise-dashboard',
    name: 'exercise-dashboard',
    component: () => import(/* webpackChunkName: "general" */ '@/views/general/ExercisDashboard.vue'),
    meta: { title: 'Exercise Dashboard' },
  },
];

/**
 * Build an array of app routes
 *
 * Function will build an array of app routes. If `prefixedRoutes` is an empty array then NO default routes will
 * be applied
 *
 * @access public
 * @param {string} prefix The app route prefix
 * @param {Array<keyof typeof appRoutes>} appRouteKeys An array of app route keys
 * @param {RouteConfig[]} [prefixedRoutes] The optional routes to prefix AFTER the default routes
 * @return {RouteConfig[]} The built app routes
 */
const buildAppRoute = (() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _mapper = (prefix: string, routes: RouteConfig[]): RouteConfig[] => {
    return routes.map(route => {
      if (route.name) {
        route.name = `${prefix}-${route.name}`;
      }

      if (route.children) {
        route.children = _mapper(prefix, [...route.children]);
      }

      return route;
    });
  };

  return (prefix: string, appRouteKeys: Array<keyof typeof appRoutes>, prefixedRoutes?: RouteConfig[]): RouteConfig[] => {
    let defaultRoutes: RouteConfig[] = [];

    if (!Array.isArray(prefixedRoutes) || prefixedRoutes.length > 0) {
      defaultRoutes = getDefaultRoutes();

      if (Array.isArray(prefixedRoutes) && prefixedRoutes.length > 0) {
        defaultRoutes = [..._mapper(prefix, [...defaultRoutes]), ...prefixedRoutes];
      }
    }

    return appRouteKeys.reduce((accumulator, appRouteKey) => {
      const childAppRoute: RouteConfig[] = appRoutes[appRouteKey].map(x => Object.assign({}, x));

      childAppRoute.forEach(route => {
        if (route.children) {
          route.children = [...route.children].map(x => ({ ...x, name: `${prefix}-${x.name}` }));
        }
      });

      return accumulator.concat([...childAppRoute]);
    }, defaultRoutes);
  };
})();

/**
 * Main router
 */
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to: any, from: any, savedPosition: any) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/page-not-found',
      component: () => import(/* webpackChunkName: "404" */ '@/views/general/PageNotFound.vue'),
      meta: { hidden: true },
    },
    {
      path: '/forbidden',
      name: 'auth-forbidden',
      component: () => import(/* webpackChunkName: "404" */ '@/views/general/Forbidden.vue'),
      meta: { hidden: true },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/auth/AuthLogin.vue'),
      meta: { hidden: true },
    },
    {
      path: '/view/video',
      name: 'view-video',
      component: () => import(/* webpackChunkName: "login" */ '@/views/view/video.vue'),
      meta: { hidden: true },
    },
    {
      path: '/',
      name: 'root',
      component: Layout,
      beforeEnter: async (to, from, next) => {
        await BrandStore.loadAll();

        next();
      },
      meta: { root: true },
      children: [
        {
          path: 'athletics',
          meta: {
            title: 'Athletics Dept',
            userRoles: [UserRole.ATHLETICS],
          },
          component: EmptyRouteView,
          children: buildAppRoute(
            'athletics',
            ['workouts', 'exercises', 'metadata', 'brands'],
            [
              {
                path: 'workout-dashboard',
                name: 'athletics-workout-dashboard',
                component: () => import(/* webpackChunkName: "general" */ '@/views/general/WorkoutDashboard.vue'),
                meta: { title: 'Workout Dashboard' },
              },
              {
                path: 'integrity-report',
                name: 'athletics-integrity-report',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/IntegrityReport.vue'),
                meta: { title: 'Integrity Check' },
              },
            ],
          ),
        },
        {
          path: 'film',
          meta: {
            title: 'Film Team',
            userRoles: [UserRole.ATHLETICS, UserRole.FILM],
          },
          component: EmptyRouteView,
          children: buildAppRoute('film', ['exercises', 'brands', 'metadata']),
        },
        {
          path: 'prod1',
          meta: {
            title: 'Prod Team 1',
            userRoles: [UserRole.ATHLETICS, UserRole.PROD1],
          },
          component: EmptyRouteView,
          children: buildAppRoute(
            'prod1',
            ['exercises', 'brands'],
            [
              {
                path: 'raw-export',
                name: 'prod1-rawvideoexport-list',
                component: () => import(/* webpackChunkName: "prod1" */ '@/views/prod1/Prod1RawVideoExportList.vue'),
                meta: { title: 'Raw Video Download' },
              },
            ],
          ),
        },
        {
          path: 'prod2',
          meta: {
            title: 'Prod Team 2',
            userRoles: [UserRole.ATHLETICS, UserRole.PROD2],
          },
          component: EmptyRouteView,
          children: buildAppRoute(
            'prod2',
            ['workouts', 'exercises', 'brands', 'metadata'],
            [
              {
                path: 'workout-dashboard',
                name: 'prod2-workout-dashboard',
                component: () => import(/* webpackChunkName: "general" */ '@/views/general/WorkoutDashboard.vue'),
                meta: { title: 'Workout Dashboard' },
              },
            ],
          ),
        },

        /**
         * Partner route
         */
        {
          path: 'partner',
          meta: {
            title: 'Partner',
            userRoles: [UserRole.PARTNER],
          },
          component: EmptyRouteView,
          children: buildAppRoute('partners', ['partners'], []),
        },

        /**
         * Admin route
         */
        {
          path: 'admin',
          meta: {
            title: 'Admin',
            isAdmin: true,
          },
          component: EmptyRouteView,
          children: buildAppRoute(
            'admin',
            ['admin'],
            [
              {
                path: 'workout-dashboard',
                name: 'athletics-workout-dashboard',
                component: () => import(/* webpackChunkName: "general" */ '@/views/general/WorkoutDashboard.vue'),
                meta: { title: 'Workout Dashboard' },
              },
            ],
          ),
        },
      ],
    },
  ],
});

/**
 * User role guard
 */
router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  /**
   * Make sure we are inside the app root
   */
  if (!to.matched[0] || !to.matched[0].meta.root) {
    return next();
  }

  AppStore.setIsLoading();

  /**
   * Unfortunately we need to handle the login here as the global `beforeEach()` gets called
   * before any route specific `beforeEnter()` calls
   */
  const isLoggedIn = await UserModel.isLoggedIn();

  if (!isLoggedIn) {
    return next({ name: 'auth-login' });
  }

  await UserModel.loginCurrent();

  /**
   * Walk up the matched tree and find the first route that has any role permissions (userRoles, isAdmin)
   */
  const route: RouteRecord | undefined = [...to.matched].reverse().find(x => (x.meta.userRoles && x.meta.userRoles.length > 0) || x.meta.isAdmin);

  if (!route) {
    return next();
  }

  /**
   * Run the perm check
   */
  if (
    !UserStore.current ||
    (route.meta.isAdmin && !UserStore.current.isAdministrator()) ||
    (route.meta.userRoles && !UserStore.current.hasRole(route.meta.userRoles, true))
  ) {
    return next({ name: 'auth-forbidden' });
  }

  next();
});

router.afterEach((to: Route) => {
  /**
   * Make sure we are inside the app root
   */
  if (!to.matched[0] || !to.matched[0].meta.root) {
    return;
  }

  AppStore.setHasLoaded();
});

export default router;
