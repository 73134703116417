


























import { Component, Vue } from 'vue-property-decorator';
import UserStore from '@/store/modules/user';
import { UserModel } from '@/models';
import { Helper } from '@/util';

@Component({
  name: 'TopBar',
})
export default class extends Vue {
  get name(): string {
    return UserStore.current?.name as string;
  }

  get role(): string {
    return UserStore.current?.roleList() as string;
  }

  get env(): string | undefined {
    return Helper.getEnv();
  }

  get isLoggedIn(): boolean {
    return UserStore.current ? true : false;
  }

  get isNonProd(): boolean {
    return !Helper.isProdEnv();
  }

  private async handleCommand(command: string): Promise<void> {
    if (command === 'logout') {
      await UserModel.logout();

      this.$router.push({ name: 'auth-login' });
    }
  }
}
