/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateId = /* GraphQL */ `
  query GenerateId {
    generateId {
      id
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      roles
      isAdmin
      isSuperAdmin
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        roles
        isAdmin
        isSuperAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserByEmail = /* GraphQL */ `
  query ListUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        roles
        isAdmin
        isSuperAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQpoint = /* GraphQL */ `
  query GetQpoint($id: ID!) {
    getQpoint(id: $id) {
      id
      code
      label
      audio
      createdAt
      updatedAt
    }
  }
`;
export const listQpoints = /* GraphQL */ `
  query ListQpoints(
    $filter: ModelQpointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQpoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        label
        audio
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      category
      productMovementSetup {
        productType
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      logoFile {
        key
        name
        mimetype
      }
      iconFile {
        key
        name
        mimetype
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExercise = /* GraphQL */ `
  query GetExercise($id: ID!) {
    getExercise(id: $id) {
      id
      name
      code
      lastUsed
      equipmentTypes
      movementTypes
      difficulty
      brandSetup {
        brandId
        status
        rawFile {
          key
          name
          mimetype
        }
        rawFileInStorage
        processedFile {
          key
          name
          mimetype
        }
        fileRef
        filmDate
        notes
        lastUsed
        amountUsed
        isGuide
        isLongLoop
        prodTeamNotes
      }
      qpoints
      productTypes
      searchName
      searchBrandList
      searchStatusList
      searchBrandStatusList
      searchWorkoutList
      searchPartnerWorkoutList
      createdAt
      updatedAt
    }
  }
`;
export const listExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        lastUsed
        equipmentTypes
        movementTypes
        difficulty
        brandSetup {
          brandId
          status
          rawFile {
            key
            name
            mimetype
          }
          rawFileInStorage
          processedFile {
            key
            name
            mimetype
          }
          fileRef
          filmDate
          notes
          lastUsed
          amountUsed
          isGuide
          isLongLoop
          prodTeamNotes
        }
        qpoints
        productTypes
        searchName
        searchBrandList
        searchStatusList
        searchBrandStatusList
        searchWorkoutList
        searchPartnerWorkoutList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkout = /* GraphQL */ `
  query GetWorkout($id: ID!) {
    getWorkout(id: $id) {
      id
      sequence
      workoutBrandId
      productTypeWorkouts {
        productType
        isInherited
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      brand {
        id
        name
        category
        productMovementSetup {
          productType
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        logoFile {
          key
          name
          mimetype
        }
        iconFile {
          key
          name
          mimetype
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWorkouts = /* GraphQL */ `
  query ListWorkouts(
    $filter: ModelWorkoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        workoutBrandId
        productTypeWorkouts {
          productType
          isInherited
          isDisabled
          exerciseSetup {
            movementType
            exerciseId
          }
          productionFile {
            key
            name
            mimetype
          }
          status
          jwPlayer
        }
        isDisabled
        searchExerciseList
        createdAt
        updatedAt
        brand {
          id
          name
          category
          productMovementSetup {
            productType
            movementTypes
          }
          trainer {
            name
            inits
          }
          workoutSetup {
            type
            setup {
              exerciseId
              movementType
              duration
            }
          }
          logoFile {
            key
            name
            mimetype
          }
          iconFile {
            key
            name
            mimetype
          }
          excelTemplateFile {
            key
            name
            mimetype
          }
          longWarmupVideoFile {
            key
            name
            mimetype
          }
          active
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBulkOps = /* GraphQL */ `
  query GetBulkOps($id: ID!) {
    getBulkOps(id: $id) {
      id
      type
      success
      error
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listBulkOpss = /* GraphQL */ `
  query ListBulkOpss(
    $filter: ModelBulkOpsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBulkOpss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        success
        error
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRawVideoExportLog = /* GraphQL */ `
  query GetRawVideoExportLog($id: ID!) {
    getRawVideoExportLog(id: $id) {
      id
      brandList
      from
      to
      compiled {
        exerciseId
        brandId
      }
      uploadPath
      emailSetup {
        email
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRawVideoExportLogs = /* GraphQL */ `
  query ListRawVideoExportLogs(
    $filter: ModelRawVideoExportLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRawVideoExportLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandList
        from
        to
        compiled {
          exerciseId
          brandId
        }
        uploadPath
        emailSetup {
          email
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      variations
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        variations
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartnerBrand = /* GraphQL */ `
  query GetPartnerBrand($id: ID!) {
    getPartnerBrand(id: $id) {
      id
      brandPartnerId
      name
      category
      variationMovementSetup {
        variation
        movementTypes
      }
      trainer {
        name
        inits
      }
      workoutSetup {
        type
        setup {
          exerciseId
          movementType
          duration
        }
      }
      excelTemplateFile {
        key
        name
        mimetype
      }
      longWarmupVideoFile {
        key
        name
        mimetype
      }
      notes
      useTemplate
      active
      createdAt
      updatedAt
      partner {
        id
        name
        variations
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPartnerBrands = /* GraphQL */ `
  query ListPartnerBrands(
    $filter: ModelPartnerBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPartnerWorkout = /* GraphQL */ `
  query GetPartnerWorkout($id: ID!) {
    getPartnerWorkout(id: $id) {
      id
      sequence
      workoutPartnerBrandId
      variationWorkouts {
        variation
        isDisabled
        exerciseSetup {
          movementType
          exerciseId
        }
        productionFile {
          key
          name
          mimetype
        }
        status
        jwPlayer
      }
      isDisabled
      searchExerciseList
      createdAt
      updatedAt
      partnerBrand {
        id
        brandPartnerId
        name
        category
        variationMovementSetup {
          variation
          movementTypes
        }
        trainer {
          name
          inits
        }
        workoutSetup {
          type
          setup {
            exerciseId
            movementType
            duration
          }
        }
        excelTemplateFile {
          key
          name
          mimetype
        }
        longWarmupVideoFile {
          key
          name
          mimetype
        }
        notes
        useTemplate
        active
        createdAt
        updatedAt
        partner {
          id
          name
          variations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPartnerWorkouts = /* GraphQL */ `
  query ListPartnerWorkouts(
    $filter: ModelPartnerWorkoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerWorkouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        workoutPartnerBrandId
        variationWorkouts {
          variation
          isDisabled
          exerciseSetup {
            movementType
            exerciseId
          }
          productionFile {
            key
            name
            mimetype
          }
          status
          jwPlayer
        }
        isDisabled
        searchExerciseList
        createdAt
        updatedAt
        partnerBrand {
          id
          brandPartnerId
          name
          category
          variationMovementSetup {
            variation
            movementTypes
          }
          trainer {
            name
            inits
          }
          workoutSetup {
            type
            setup {
              exerciseId
              movementType
              duration
            }
          }
          excelTemplateFile {
            key
            name
            mimetype
          }
          longWarmupVideoFile {
            key
            name
            mimetype
          }
          notes
          useTemplate
          active
          createdAt
          updatedAt
          partner {
            id
            name
            variations
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
