import { ErrorList } from 'async-validator';
import { DBDocument, DBModel, DBSchema, IDocument } from '../lib';
import {
  EquipmentType,
  EquipmentTypeList,
  EquipmentTypeOptions,
  ExerciseDifficulty,
  ExerciseVideoStatusType,
  MovementType,
  MovementTypeList,
  ProductType,
  ProductTypeList,
  ProductTypeOptions,
  WarmUpCoolDownMovementType,
} from '@/constants';
import { IS3Object, S3ObjectSchema } from '../shared';
import BrandModel, { BrandDocument } from '../brand';
import WorkoutModel, { WorkoutDocument } from '../workout';
import _ from 'lodash';
import { Helper } from '@/util';
import assert from 'assert';

const codeCountPrefix = 'EX';

export interface IExerciseBrandSetup {
  brandId: string;
  status: ExerciseVideoStatusType;
  rawFile?: IS3Object;
  rawFileInStorage?: boolean;
  processedFile?: IS3Object;
  fileRef?: string;
  filmDate?: Date;
  notes?: string | null;
  lastUsed?: Date | null;
  amountUsed?: number | null;
  isGuide?: boolean;
  isLongLoop?: boolean;
  prodTeamNotes?: string | null;
}

export interface IExercise extends IDocument {
  name: string;
  code: string;
  lastUsed?: Date | null;
  equipmentTypes: EquipmentType[];
  movementTypes: MovementType[];
  difficulty: ExerciseDifficulty;
  brandSetup: IExerciseBrandSetup[];
  productTypes: ProductType[];
  qpoints: string[];

  searchName: string;
  searchBrandList: string[];
  searchStatusList: string[];
  searchBrandStatusList: string[];
  searchWorkoutList: string[];
  searchPartnerWorkoutList: string[];
}

export class ExerciseDocument extends DBDocument<ExerciseDocument, IExercise> implements IExercise {
  public name: string = '';
  public code: string = '';
  public lastUsed?: Date | null;
  public equipmentTypes: EquipmentType[] = [];
  public movementTypes: MovementType[] = [];
  public difficulty: ExerciseDifficulty = ExerciseDifficulty.DIFFICULT;
  public brandSetup: IExerciseBrandSetup[] = [];
  public qpoints: string[] = [];
  public productTypes: ProductType[] = [];

  public searchName: string = '';
  public searchBrandList: string[] = [];
  public searchStatusList: string[] = [];
  public searchBrandStatusList: string[] = [];
  public searchWorkoutList: string[] = [];
  public searchPartnerWorkoutList: string[] = [];

  private $initCode: string = '';

  addBrandSetup(brand: BrandDocument): void {
    const noOfBrands = this.brandSetup.length;
    const prodTeamNotes = noOfBrands > 0 ? this.brandSetup[0].prodTeamNotes : '';
    this.brandSetup.push({
      brandId: brand.id,
      status: ExerciseVideoStatusType.READY_TO_FILM,
      isGuide: false,
      isLongLoop: false,
      prodTeamNotes,
    });
  }

  getSelectedBrands(): string[] {
    return this.brandSetup.map(x => x.brandId);
  }

  postLoad(): void {
    /**
     * Make the code property readonly if we have one
     */
    if (!_.isString(this.code) || this.code === '') {
      return;
    }

    this.$initCode = this.code;
  }

  async preSave(): Promise<void> {
    this.productTypes = this.getMatchedProductTypes();

    /**
     * @TODO Fix the initial bug
     */
    const usedBandIds: string[] = [];

    this.brandSetup = this.brandSetup.filter(setup => {
      if (usedBandIds.includes(setup.brandId)) {
        return false;
      }

      usedBandIds.push(setup.brandId);

      return true;
    });

    this.qpoints = this.qpoints.filter(x => !!x);

    /**
     * Real hacky but amplify cannot search through nested objects
     */
    this.searchName = this.name.toLowerCase();
    this.searchBrandList = (this.brandSetup || []).reduce((accumulator, value) => accumulator.concat(value.brandId), [] as string[]);
    this.searchStatusList = (this.brandSetup || []).reduce((accumulator, value) => accumulator.concat(value.status), [] as string[]);
    this.searchBrandStatusList = (this.brandSetup || []).reduce((accumulator, value) => accumulator.concat(`${value.brandId}|${value.status}`), [] as string[]);

    if (this.isNew() || !this.$initCode) {
      this.searchWorkoutList = [];

      if (Helper.isTestMode() && this.code !== '') {
        assert(this.code.substr(0, codeCountPrefix.length) === codeCountPrefix, `Invalid code value for test (code: '${this.code}')`);

        return;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const exercises = await exerciseModel.find();

        const highestCodeNo: number = exercises.reduce((accumulator, exercise) => {
          const codeNo: number = exercise.code ? parseInt(exercise.code.substr(codeCountPrefix.length)) : 0;

          return Math.max(accumulator, !isNaN(codeNo) ? codeNo : 0);
        }, -1);

        this.code = `${codeCountPrefix}${highestCodeNo + 1}`;
      }
    } else {
      assert(this.$initCode);

      this.code = this.$initCode;
    }
  }

  public getMatchedProductTypes(): ProductType[] {
    const equipmentToProductTypeMap: Record<EquipmentType, ProductType[]> = EquipmentTypeOptions.reduce(
      (accumulator, equipmentSetup) => ({ ...accumulator, [equipmentSetup.key]: equipmentSetup.productTypes }),
      {} as Record<EquipmentType, ProductType[]>,
    );

    return ProductTypeOptions.reduce((accumulator, productTypeSetup) => {
      const productType: ProductType = productTypeSetup.key;

      let pass = this.equipmentTypes.every(x => equipmentToProductTypeMap[x].includes(productType));

      if (pass && productTypeSetup.exerciseFilter) {
        pass = productTypeSetup.exerciseFilter(this);
      }

      if (pass) {
        accumulator.push(productType);
      }

      return accumulator;
    }, [] as ProductType[]);
  }

  public async checkIntegrity(): Promise<void> {
    const oldDoc: ExerciseDocument = await this.$model.findById(this.id);
    const workouts: WorkoutDocument[] = await WorkoutModel.find({ searchExerciseList: { contains: this.id } });
    const brands: BrandDocument[] = await BrandModel.find();

    /**
     * Check removed brands/product type and movement types and if it will affect any workouts
     */
    if (workouts.length > 0) {
      const doError = (label: string, foundWorkouts: WorkoutDocument[]) => {
        const usedBrandIds = Array.from(new Set(foundWorkouts.map(x => x.workoutBrandId)));

        throw new Error(
          `${label} as this exercise is already used in workouts for ${brands
            .filter(x => usedBrandIds.includes(x.id))
            .map(x => x.name)
            .join(', ')}`,
        );
      };

      /**
       * Removed brands first
       */
      const newBrands = this.brandSetup.map(x => x.brandId);
      const oldBrands = oldDoc.brandSetup.map(x => x.brandId);
      const removedBrands = oldBrands.filter(x => !newBrands.includes(x));

      if (removedBrands.length > 0) {
        const foundWorkouts = workouts.filter(x => removedBrands.includes(x.workoutBrandId));

        if (foundWorkouts.length > 0) {
          doError('Cannot change brands', foundWorkouts);
        }
      }

      /**
       * Removed product types
       */
      const removedProductTypes = oldDoc.productTypes.filter(x => !this.productTypes.includes(x));

      if (removedProductTypes.length > 0) {
        const foundWorkouts = workouts.filter(x => x.productTypeWorkouts.some(type => removedProductTypes.includes(type.productType)));

        if (foundWorkouts.length > 0) {
          doError(`Cannot remove suitability types "${removedProductTypes.map(type => ProductTypeList[type]).join(', ')}"`, foundWorkouts);
        }
      }

      /**
       * Removed movement type
       */
      const removedMovementTypes = oldDoc.movementTypes.filter(x => !this.movementTypes.includes(x));

      if (removedMovementTypes.length > 0) {
        const usedMovementTypes: MovementType[] = [];
        const foundWorkouts = workouts.reduce((accumulator, workout) => {
          let isUsed = false;

          workout.productTypeWorkouts.forEach(type => {
            type.exerciseSetup.forEach(setup => {
              if (removedMovementTypes.includes(setup.movementType)) {
                isUsed = true;

                if (!usedMovementTypes.includes(setup.movementType)) {
                  usedMovementTypes.push(setup.movementType);
                }
              }
            });
          });

          if (isUsed) {
            accumulator.push(workout);
          }

          return accumulator;
        }, [] as WorkoutDocument[]);

        if (foundWorkouts.length > 0) {
          doError(`Cannot remove movement types "${usedMovementTypes.map(type => MovementTypeList[type]).join(', ')}"`, foundWorkouts);
        }
      }
    }

    // const selectedProductTypes: ProductType[] = this.getMatchedProductTypes();

    // /**
    //  * Check if equipment types satisfy the suitability
    //  */
    // selectedProductTypes.forEach(type => {
    //   const requiredEquipment = EquipmentTypeOptions.filter(x => x.productTypes.includes(type)).map(x => x.key);

    //   if (!requiredEquipment.some(x => this.equipmentTypes.includes(x))) {
    //     throw new Error(`The rig/kit type "${ProductTypeList[type]}" does not have any matching equipment types`);
    //   }
    // });

    // /**
    //  * And vise-versa
    //  */
    // this.equipmentTypes.forEach((equipmentType: EquipmentType) => {
    //   if (!EquipmentTypeOptions.find(x => x.key === equipmentType)?.productTypes.some(x => selectedProductTypes.includes(x))) {
    //     throw new Error(`The equipment type "${EquipmentTypeList[equipmentType]} does not have any matching rig/kit`);
    //   }
    // });
  }
}

export class ExerciseModel extends DBModel<ExerciseDocument, IExercise> {
  async findByBrandId(brandId: string, excludeExerciseId?: string): Promise<ExerciseDocument[]> {
    const filter: any = {
      searchBrandList: { contains: brandId },
    };

    if (excludeExerciseId) {
      filter.id = { ne: excludeExerciseId };
    }

    return this.find(filter);
  }

  async findByBrandIdAndProductType(brandId: string, productType: string, excludeExerciseId?: string): Promise<ExerciseDocument[]> {
    const filter: any = {
      searchBrandList: { contains: brandId },
      productTypes: { contains: productType },
    };

    if (excludeExerciseId) {
      filter.id = { ne: excludeExerciseId };
    }

    return this.find(filter);
  }

  async findAllWarmUpCoolDown(): Promise<ExerciseDocument[]> {
    return this.find({ or: WarmUpCoolDownMovementType.map(x => ({ movementTypes: { contains: x } })) });
  }

  async findAllWarmUpCoolDownByBrandId(brandId: string): Promise<ExerciseDocument[]> {
    const filter: Record<string, any> = {
      searchBrandList: { contains: brandId },
      or: WarmUpCoolDownMovementType.map(x => ({ movementTypes: { contains: x } })),
    };

    return this.find(filter);
  }

  async createNew(data?: Partial<IExercise>): Promise<ExerciseDocument> {
    const doc: ExerciseDocument = await this.createWithId(data);

    doc.qpoints = ['', '', ''];

    /**
     * Can't use the postLoad document hook as that hook is not asynchronous
     */
    const brands: BrandDocument[] = await BrandModel.find();

    brands.forEach(brand => doc.addBrandSetup(brand));

    // doc.productTypes = Object.keys(ProductType) as ProductType[];

    return doc;
  }
}

const setup: DBSchema = {
  name: {
    type: 'string',
    required: true,
  },
  code: {
    type: 'string',
    required: false,
  },
  lastUsed: {
    type: 'date',
  },
  equipmentTypes: {
    type: 'array',
    required: true,
    defaultField: {
      type: 'enum',
      enum: Object.keys(EquipmentType),
    },
  },
  movementTypes: {
    type: 'array',
    required: true,
    defaultField: {
      type: 'enum',
      enum: Object.keys(MovementType),
    },
  },
  difficulty: {
    type: 'enum',
    required: true,
    enum: Object.keys(ExerciseDifficulty),
  },
  qpoints: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  brandSetup: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        brandId: {
          type: 'string',
          required: true,
        },
        status: {
          type: 'enum',
          enum: Object.keys(ExerciseVideoStatusType),
        },
        rawFile: {
          type: 'object',
          fields: S3ObjectSchema,
        },
        rawFileInStorage: {
          type: 'boolean',
        },
        processedFile: {
          type: 'object',
          fields: S3ObjectSchema,
        },
        fileRef: {
          type: 'string',
        },
        filmDate: {
          type: 'date',
        },
        notes: {
          type: 'string',
        },
        lastUsed: {
          type: 'date',
        },
        amountUsed: {
          type: 'number',
        },
        isGuide: {
          type: 'boolean',
        },
        isLongLoop: {
          type: 'boolean',
        },
        prodTeamNotes: {
          type: 'string',
        },
      },
    },
  },
  productTypes: {
    type: 'array',
    defaultField: {
      type: 'enum',
      enum: Object.keys(ProductType),
    },
  },
  searchName: {
    type: 'string',
  },
  searchBrandList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  searchStatusList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  searchBrandStatusList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
  searchWorkoutList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
};

const exerciseModel = new ExerciseModel('Exercise', ExerciseDocument, setup);

export default exerciseModel;
