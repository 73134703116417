import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { UserModel, UserDocument } from '@/models';
import { UserRole } from '@/constants';

export interface IUserState {
  current: UserDocument | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'user' })
class UserStore extends VuexModule implements IUserState {
  public current: UserDocument | null = null;

  @Mutation
  LOGIN(user: UserDocument) {
    this.current = user;
  }

  @Action({ commit: 'LOGIN' })
  async doLogin(email: string) {
    const user: UserDocument = await UserModel.findByEmail(email);

    if (!user) {
      throw new Error(`Cannot find matching user (email: ${email}`);
    }

    return user;
  }
}

export default getModule(UserStore);
