/**
 * Escape a CSV string
 *
 * Function will escape the string to be used in a CSV cell
 *
 * @access public
 * @param {any} value The string to escape
 * @returns {string} The escaped string
 */
export const escape = (value: any): string => {
  if (Array.isArray(value)) {
    return escape(value.join(', '));
  } else {
    return `"${value.toString().replace(/"/g, '""')}"`;
  }
};

export const escapeArray = (line: any[]) => line.map(escape).join(',');

/**
 * Convert and array of objects to a csv string
 *
 * Method will convert the array of objects to the csv string
 *
 * @access public
 * @param {Array<Partial<Record<string, any>>>} entries The array to convert
 * @param {Record<string, string>} columnMap The optional column mapper. Default is to use the fiurst records keys
 * @return {string} The converted CSV string
 */
export const fromArray = (entries: Array<Partial<Record<string, any>> | null>, columnMap?: Record<string, string>): string => {
  const csv: string[] = [];
  const addLine = (line: any[]): void => {
    csv.push(escapeArray(line));
  };

  let keys: string[] = [];

  if (columnMap) {
    keys = Object.keys(columnMap);
    addLine(Object.values(columnMap));
  } else {
    keys = Object.keys(entries.find(entry => !!entry) as Record<string, any>);
    addLine(keys);
  }

  entries.forEach(entry => {
    if (!entry) {
      csv.push('');
    } else {
      addLine(keys.map(key => entry[key as any] || ''));
    }
  });

  return csv.join('\n');
};
