import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';

export interface IAppState {
  loading: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: 'app' })
class AppStore extends VuexModule implements IAppState {
  public loading: boolean = false;

  @Mutation
  SET_LOADING(loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'SET_LOADING' })
  async setIsLoading() {
    return true;
  }

  @Action({ commit: 'SET_LOADING' })
  async setHasLoaded() {
    return false;
  }
}

export default getModule(AppStore);
