import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { QpointModel, QpointDocument } from '@/models';

export interface IQpointState {
  list: QpointDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'qpoint' })
class QpointStore extends VuexModule implements IQpointState {
  public list: QpointDocument[] = [];

  @Mutation
  SET_LIST(qpoints: QpointDocument[]) {
    this.list = qpoints;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<QpointDocument[]> {
    return QpointModel.find();
  }
}

export default getModule(QpointStore);
