import Vue from 'vue';

/**
 * Need to register hooks before importing any components
 */
import Component from 'vue-class-component';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

import App from './App.vue';

import store from './store';
import router from './router';

import './plugins/element-ui';
import '@/styles/index.scss';

import Amplify, { Auth } from 'aws-amplify';
import '@aws-amplify/ui-vue';

/**
 * Using "require" to avoid allowing JS imports
 */
const awsconfig = require('./aws-exports').default;

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
