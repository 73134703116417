














import { Component, Vue } from 'vue-property-decorator';
import { MainContent, TopBar, NavBar } from './components';
import AppStore from '@/store/modules/app';
import { Helper } from '@/util';

@Component({
  name: 'Layout',
  components: {
    MainContent,
    TopBar,
    NavBar,
  },
})
export default class extends Vue {
  get loading(): boolean {
    return AppStore.loading;
  }
  get isNonProd(): boolean {
    return !Helper.isProdEnv();
  }
}
