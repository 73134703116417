import { CategoryType, MovementType, ExerciseDurationTime, BrandWorkoutSetupType, BrandWorkoutSetupTypeList } from '@/constants';
import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';
import { IS3Object, S3ObjectSchema, S3ObjectDefaultValue } from '../shared';
import { IBrandWorkoutSetup, IBrandTrainer } from '../brand';
import { PartnerModel, IPartner, PartnerWorkoutModel } from '..';
import assert from 'assert';

export interface IPartnerBrandVariationMovementSetup {
  variation: string;
  movementTypes: MovementType[];
}

export interface IPartnerBrand extends IDocument {
  name: string;
  category: CategoryType;
  brandPartnerId: string;
  partner?: IPartner;
  variationMovementSetup: IPartnerBrandVariationMovementSetup[];
  trainer: IBrandTrainer;
  workoutSetup: IBrandWorkoutSetup[];
  excelTemplateFile: IS3Object;
  longWarmupVideoFile: IS3Object;
  useTemplate: string;
  notes: string;
  active: boolean;
}

export class PartnerBrandDocument extends DBDocument<PartnerBrandDocument, IPartnerBrand> implements IPartnerBrand {
  name: string = '';
  category: CategoryType = CategoryType.CARDIO;
  brandPartnerId: string = '';
  partner?: IPartner;
  variationMovementSetup: IPartnerBrandVariationMovementSetup[] = [];
  trainer: IBrandTrainer = {
    name: '',
    inits: '',
  };
  workoutSetup: IBrandWorkoutSetup[] = [];
  excelTemplateFile: IS3Object = S3ObjectDefaultValue;
  longWarmupVideoFile: IS3Object = S3ObjectDefaultValue;
  useTemplate: string = '';
  notes: string = '';
  active: boolean = true;

  async preRemove(): Promise<void> {
    const totalWorkouts = await PartnerWorkoutModel.count({ workoutPartnerBrandId: { eq: this.id } });

    if (totalWorkouts > 0) {
      throw new Error('Unable to delete as this brand current has workouts');
    }
  }
}

export class PartnerBrandModel extends DBModel<PartnerBrandDocument, IPartnerBrand> {
  async createNew(partnerId: string, data?: Partial<IPartnerBrand>): Promise<PartnerBrandDocument> {
    const partner = await PartnerModel.findById(partnerId);

    assert(partner, 'Cannot find matching partner ID');

    const doc = await this.createWithId(data);

    doc.brandPartnerId = partnerId;

    doc.variationMovementSetup = partner.variations.reduce(
      (accumulator, variation) => [...accumulator, { variation, movementTypes: [MovementType.BODYWEIGHT] }],
      [] as IPartnerBrandVariationMovementSetup[],
    );

    doc.workoutSetup = Object.keys(BrandWorkoutSetupTypeList).reduce(
      (accumulator, type) => [...accumulator, { type: type as BrandWorkoutSetupType, setup: [] }],
      [] as IBrandWorkoutSetup[],
    );

    return doc;
  }
}

const setup: DBSchema = {
  name: {
    type: 'string',
    required: true,
  },
  category: {
    type: 'enum',
    required: true,
    enum: Object.keys(CategoryType),
  },
  brandPartnerId: {
    type: 'string',
    required: true,
    ref: 'partner',
  },
  variationMovementSetup: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        variation: {
          type: 'string',
        },
        movementTypes: {
          type: 'array',
          defaultField: {
            type: 'enum',
            enum: Object.keys(MovementType),
          },
        },
      },
    },
  },
  trainer: {
    type: 'object',
    fields: {
      name: {
        type: 'string',
        required: true,
      },
      inits: {
        type: 'string',
        required: true,
      },
    },
  },
  workoutSetup: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        type: {
          type: 'enum',
          required: true,
          enum: Object.keys(BrandWorkoutSetupType),
        },
        setup: {
          type: 'array',
          defaultField: {
            type: 'object',
            fields: {
              exerciseId: {
                type: 'string',
                required: true,
              },
              movementType: {
                type: 'enum',
                required: true,
                enum: Object.keys(MovementType),
              },
              duration: {
                type: 'enum',
                required: true,
                enum: ExerciseDurationTime,
              },
            },
          },
        },
      },
    },
  },
  excelTemplateFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  longWarmupVideoFile: {
    type: 'object',
    fields: S3ObjectSchema,
  },
  useTemplate: {
    type: 'string',
  },
  notes: {
    type: 'string',
  },
  active: {
    type: 'boolean',
  },
};

const partnerBrandModel = new PartnerBrandModel('PartnerBrand', PartnerBrandDocument, setup);

export default partnerBrandModel;
