import { DBDocument, DBModel, DBSchema, IDocument } from '../lib';
import { IS3Object, S3ObjectDefaultValue, S3ObjectSchema } from '../shared';
import { MovementType, WorkoutVideoStatusType } from '@/constants';
import PartnerBrandModel, { PartnerBrandDocument, IPartnerBrand } from './partnerbrand';
import ExerciseModel, { ExerciseDocument } from '../exercise';
import assert from 'assert';

export interface IPartnerWorkoutBrandVariationOptionSetup {
  movementType: MovementType;
  exercises: Array<{
    id: string;
    name: string;
  }>;
}

export interface IPartnerWorkoutBrandVariationOption {
  variation: string;
  setup: IPartnerWorkoutBrandVariationOptionSetup[];
}

export interface IPartnerWorkoutBrandVariationExerciseSetup {
  movementType: MovementType;
  exerciseId: string;
}

export interface IPartnerWorkoutBrandVariation {
  isDisabled: boolean;
  variation: string;
  exerciseSetup: IPartnerWorkoutBrandVariationExerciseSetup[];
  productionFile: IS3Object;
  status: WorkoutVideoStatusType;
  jwPlayer: string;
}

export interface IPartnerWorkout extends IDocument {
  workoutPartnerBrandId: string;
  sequence: number;
  partnerBrand?: IPartnerBrand;
  variationWorkouts: IPartnerWorkoutBrandVariation[];
  isDisabled: boolean;

  searchExerciseList: string[];
}

export class PartnerWorkoutDocument extends DBDocument<PartnerWorkoutDocument, IPartnerWorkout> implements IPartnerWorkout {
  public workoutPartnerBrandId: string = '';
  public partnerBrand?: IPartnerBrand;
  public sequence: number = 0;
  public variationWorkouts: IPartnerWorkoutBrandVariation[] = [];
  public isDisabled: boolean = false;

  public searchExerciseList: string[] = [];

  public async preSave(isUpdate: boolean): Promise<void> {
    /**
     * Update our search fields
     */
    this.searchExerciseList = this.variationWorkouts.reduce(
      (accumulator, variationSetup) => [...accumulator, ...variationSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    this.searchExerciseList = [...new Set(this.searchExerciseList)];

    if (isUpdate) {
      return;
    }

    /**
     * Set the sequence on inserts
     */
    const total: number = await this.$model.count({ workoutPartnerBrandId: { eq: this.workoutPartnerBrandId } });

    this.sequence = (total || 0) + 1;
  }

  public async postSave(): Promise<void> {
    /**
     * Get all the exercises used
     */
    let exerciseIDList: string[] = this.variationWorkouts.reduce(
      (accumulator, productSetup) => [...accumulator, ...productSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    exerciseIDList = Array.from(new Set(exerciseIDList));

    const exercises = await ExerciseModel.find({ or: exerciseIDList.map(x => ({ id: { eq: x } })) });

    await exercises.map(
      async (exercise): Promise<void> => {
        exercise.searchPartnerWorkoutList = exercise.searchPartnerWorkoutList || [];

        if (exercise.searchPartnerWorkoutList.includes(this.id)) {
          return;
        }

        exercise.searchPartnerWorkoutList.push(this.id);

        await exercise.save();
      },
    );
  }

  public async postRemove(): Promise<void> {
    /**
     * Get all the exercises used
     */
    let exerciseIDList: string[] = this.variationWorkouts.reduce(
      (accumulator, productSetup) => [...accumulator, ...productSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    exerciseIDList = Array.from(new Set(exerciseIDList));

    const exercises = await ExerciseModel.find({ or: exerciseIDList.map(x => ({ id: { eq: x } })) });

    await exercises.map(
      async (exercise): Promise<void> => {
        exercise.searchPartnerWorkoutList = exercise.searchPartnerWorkoutList || [];

        // Remove workout from exercise search work list
        exercise.searchPartnerWorkoutList = exercise.searchPartnerWorkoutList.filter(workoutId => workoutId !== this.id);

        await exercise.save();
      },
    );
  }

  public async disable(): Promise<void> {
    if (this.isNew()) {
      console.log('New?');
      return;
    }

    this.isDisabled = true;

    return this.save();
  }
}

export class PartnerWorkoutModel extends DBModel<PartnerWorkoutDocument, IPartnerWorkout> {
  /**
   * Create a new document based on the supplied partner brand ID
   *
   * Method will create a new PartnerWorkoutDocument and initialise the variation exercises based on the
   * supplied partner brand ID
   *
   * @asycn
   * @access public
   * @param {string} partnerBrandId The partner brand ID
   * @param {Partial<IPartnerWorkout>} data The optional initialisation data
   * @return {Promise<PartnerWorkoutDocument>} The workout document
   */
  async createNew(partnerBrandId: string, data?: Partial<IPartnerWorkout>): Promise<PartnerWorkoutDocument> {
    assert(partnerBrandId, 'Missing/Invalid partner brand ID');

    const [partnerBrand, movementTypeMap] = await Promise.all([
      PartnerBrandModel.findById(partnerBrandId),
      (await ExerciseModel.find({})).reduce((accumulator, exercise) => {
        exercise.movementTypes.forEach(x => {
          if (!accumulator[x]) {
            accumulator[x] = [];
          }

          accumulator[x].push(exercise);
        });

        return accumulator;
      }, {} as Record<string, ExerciseDocument[]>),
    ]);

    assert(partnerBrand, 'Unmatched partner brand ID');

    const doc: PartnerWorkoutDocument = this.create({ ...(data || {}), workoutPartnerBrandId: partnerBrandId });

    doc.variationWorkouts = partnerBrand.variationMovementSetup.map(setup => ({
      variation: setup.variation,
      isDisabled: false,
      exerciseSetup: setup.movementTypes.map(movementType => {
        const exercisesInUse: string[] = [];
        const movementTypeSetup = {
          movementType,
          exerciseId: '',
        };

        if (movementTypeMap[movementType] && movementTypeMap[movementType].length > 0) {
          const unusedExercises = movementTypeMap[movementType].filter(x => !exercisesInUse.includes(x.id));

          if (unusedExercises.length > 0) {
            movementTypeSetup.exerciseId = unusedExercises[0].id;

            /**
             * Use the same one twice if we have no choice
             */
          } else {
            movementTypeSetup.exerciseId = movementTypeMap[movementType][0].id;
          }
        }

        return movementTypeSetup;
      }),
      productionFile: S3ObjectDefaultValue,
      status: WorkoutVideoStatusType.NOT_READY,
      jwPlayer: '',
    }));

    return doc;
  }

  async findByPartnerBrandId(partnerBrandId: string): Promise<PartnerWorkoutDocument[]> {
    return await this.find({ workoutPartnerBrandId: { eq: partnerBrandId }, isDisabled: false });
  }

  public async findById(id: string): Promise<PartnerWorkoutDocument> {
    const workout = await super.findById(id);

    if (workout && !workout.isDisabled) {
      return workout;
    }

    return (undefined as unknown) as PartnerWorkoutDocument;
  }

  public async find(filter?: Record<string, any>): Promise<PartnerWorkoutDocument[]> {
    return super.find({ ...(filter || {}), isDisabled: { eq: false } });
  }

  async getExercises(workoutId: string): Promise<ExerciseDocument[]> {
    const workout = await this.findById(workoutId);

    if (!workout) {
      return [];
    }

    return ExerciseModel.findByIdList(workout.searchExerciseList);
  }

  async getExerciseOptions(partnerBrandId: string): Promise<IPartnerWorkoutBrandVariationOption[]> {
    assert(partnerBrandId, 'Missing/Invalid partner brand ID');

    const partnerBrand: PartnerBrandDocument = (await PartnerBrandModel.findById(partnerBrandId)) as PartnerBrandDocument;

    assert(partnerBrand, 'Unmatched partner brand ID');

    /**
     * @TODO For now we're assuming that ALL exercises use ALL variations, regardless of partner brand
     */
    const exercises = (await ExerciseModel.find()).map(x => ({
      id: x.id,
      name: x.name,
    }));

    return partnerBrand.variationMovementSetup.map(x => ({
      variation: x.variation,
      setup: x.movementTypes.map(movement => ({
        movementType: movement,
        exercises,
      })),
    }));
  }
}

const setup: DBSchema = {
  workoutPartnerBrandId: {
    type: 'string',
    required: true,
    ref: 'partnerBrand',
  },
  sequence: {
    type: 'number',
  },
  isDisabled: {
    type: 'boolean',
  },
  variationWorkouts: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        variation: {
          type: 'string',
        },
        exerciseSetup: {
          type: 'array',
          defaultField: {
            type: 'object',
            fields: {
              movementType: {
                type: 'enum',
                enum: Object.keys(MovementType),
              },
              exerciseId: {
                type: 'string',
              },
            },
          },
        },
        isDisabled: {
          type: 'boolean',
        },
        productionFile: {
          type: 'object',
          fields: S3ObjectSchema,
        },
        status: {
          type: 'enum',
          enum: Object.keys(WorkoutVideoStatusType),
        },
        jwPlayer: {
          type: 'string',
        },
      },
    },
  },
  searchExerciseList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
};

const partnerWorkoutModel = new PartnerWorkoutModel('PartnerWorkout', PartnerWorkoutDocument, setup);

export default partnerWorkoutModel;
