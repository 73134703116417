import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { BrandModel, BrandDocument } from '@/models';

export interface IBrandState {
  current: BrandDocument | null;
  list: BrandDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'brand' })
class BrandStore extends VuexModule implements IBrandState {
  public current: BrandDocument | null = null;
  public list: BrandDocument[] = [];

  @Mutation
  SET_CURRENT(brand: BrandDocument) {
    this.current = brand;
  }

  @Mutation
  SET_LIST(brands: BrandDocument[]) {
    this.list = brands;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<BrandDocument[]> {
    return BrandModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(): Promise<BrandDocument> {
    return BrandModel.createNew();
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<BrandDocument | void> {
    return BrandModel.findById(id);
  }
}

export default getModule(BrandStore);
