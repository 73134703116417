import _ from 'lodash';
import { Rules, RuleItem } from 'async-validator';
import { DBSchema } from '@/models/lib';
import { RawLocation, Location, Route } from 'vue-router';
import Router from '@/router';

export const isTestMode = (): boolean => process.env.NODE_ENV === 'test';

export const isProdEnv = (): boolean => process.env.NODE_ENV === 'production';

export const getEnv = (): string | undefined => {
  return process.env.NODE_ENV;
};

export const schemaToRules = (schema: DBSchema, trigger: string = 'blur', overrideTriggerMap: Record<string, string> = {}): Rules => {
  return _.reduce(
    schema,
    (accumulator, rule, key) => {
      accumulator[key] = { ...(rule as RuleItem), trigger: overrideTriggerMap[key] || trigger } as RuleItem;

      return accumulator;
    },
    {} as Rules,
  );
};

const updateRawLocation = (rawLocation: RawLocation, overridePrefix?: string): boolean => {
  if (!_.isString(rawLocation) && (overridePrefix || (rawLocation as Location).name)) {
    rawLocation.name = `${overridePrefix || Router.currentRoute.name?.split('-')[0]}-${rawLocation.name}`;
    return true;
  }

  return false;
};

/**
 * Push a new route with appended `name` property
 *
 * Function is a wrapper for finding the `name` property of the raw location and updating it with the current route's
 * `name` property
 *
 * @access public
 * @param {RawLocation} rawLocation The raw location
 * @returns {Promise<Route>} The new pushed route
 */
export const routeTo = async (rawLocation: RawLocation): Promise<Route> => {
  updateRawLocation(rawLocation);

  return Router.push(rawLocation);
};

/**
 * Construct href string with appended `name` property
 *
 * Function is a wrapper for finding the `name` property of the raw location and updating it with the current route's
 * `name` property
 *
 * @access public
 * @param {RawLocation} rawLocation The raw location
 * @param {string} [overridePrefix] The optional prefix used in the location name
 * @returns {string} The route href string
 */
export const routeHref = (rawLocation: RawLocation, overridePrefix?: string): string => {
  if (!updateRawLocation(rawLocation, overridePrefix)) {
    return (rawLocation as Location).path as string;
  }

  const routeData = Router.resolve(rawLocation);

  return routeData.href;
};

/**
 * Build search JSON OR query
 */

export const buildOrQuery = (property: string, list: any[]): Record<string, any[]> => ({ or: list.map(x => ({ [property]: { contains: x } })) });

//   const queries = list.map(value => {
//     return { [property]: { contains: value } };
//   });
//   return { or: queries };
// };
