/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-empty-interface */
import _ from 'lodash';
import { DBDocument, DBModel, DBSchema, IDocument } from '../lib';
import { IS3Object, S3ObjectDefaultValue, S3ObjectSchema } from '../shared';
import { EquipmentType, EquipmentTypeOptions, MovementType, ProductType, ProductTypeList, ProductTypeOptions, WorkoutVideoStatusType } from '@/constants';
import BrandModel, { BrandDocument, IBrand, IBrandProductMovementSetup } from '../brand';
import ExerciseModel, { ExerciseDocument } from '../exercise';
import assert from 'assert';

export type WorkoutMovementTypeCompareStatus =
  | 'ok'
  | 'cannot-inherit-movement-type'
  | 'cannot-inherit-different-movement-type'
  | 'cannot-inherit-exercise'
  | 'inherited-different-movement-type'
  | 'different-movement-type';

export interface IWorkoutExerciseSetupBase {
  movementType: MovementType;
}

export interface IWorkoutSetupBase<T extends IWorkoutExerciseSetupBase> {
  productType: ProductType;
  exerciseSetup: T[];
}

export interface IWorkoutBrandProductTypeOptionExercises extends IWorkoutExerciseSetupBase {
  exercises: Array<{
    id: string;
    name: string;
    isGreyListed: boolean;
  }>;
}

export interface IWorkoutBrandProductTypeOption extends IWorkoutSetupBase<IWorkoutBrandProductTypeOptionExercises> {}

export interface IWorkoutBrandProductTypeExercise extends IWorkoutExerciseSetupBase {
  exerciseId: string;
}

export interface IWorkoutBrandProductType extends IWorkoutSetupBase<IWorkoutBrandProductTypeExercise> {
  isDisabled: boolean;
  isInherited: boolean;
  productionFile: IS3Object;
  status: WorkoutVideoStatusType;
  jwPlayer: string;
}

export interface IWorkout extends IDocument {
  workoutBrandId: string;
  sequence: number;
  brand?: IBrand;
  productTypeWorkouts: IWorkoutBrandProductType[];
  isDisabled: boolean;

  searchExerciseList: string[];
}

export interface IWorkoutMatchingProductTypes {
  productTypes: ProductType[];
  exerciseSetup: IWorkoutBrandProductTypeExercise[];
}

const productTypeHierarchy = Object.keys(ProductTypeList) as ProductType[];

export class WorkoutDocument extends DBDocument<WorkoutDocument, IWorkout> implements IWorkout {
  public workoutBrandId: string = '';
  public sequence: number = 0;
  public brand?: IBrand;
  public productTypeWorkouts: IWorkoutBrandProductType[] = [];
  public isDisabled: boolean = false;

  public searchExerciseList: string[] = [];

  public async preSave(isUpdate: boolean): Promise<void> {
    /**
     * Compile all inheritence in the productTypeWorkouts
     */
    const matchingProductTypes: IWorkoutMatchingProductTypes[] = this.getMatchingProductTypes();

    matchingProductTypes.forEach((matchingProductType: IWorkoutMatchingProductTypes): void => {
      matchingProductType.productTypes.slice(1).forEach((productType: ProductType): void => {
        (this.productTypeWorkouts.find(x => x.productType === productType) as IWorkoutBrandProductType).isInherited = true;
      });
    });

    /**
     * Update our search fields
     */
    this.searchExerciseList = this.productTypeWorkouts.reduce(
      (accumulator, productTypeSetup) => [...accumulator, ...productTypeSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    this.searchExerciseList = [...new Set(this.searchExerciseList)];

    if (isUpdate) {
      return;
    }

    /**
     * Set the sequence on inserts
     */
    const total: number = await this.$model.count({ workoutBrandId: { eq: this.workoutBrandId } });

    this.sequence = (total || 0) + 1;
  }

  /**
   * @TODO Need a hook system
   */
  public async postSave(): Promise<void> {
    /**
     * Get all the exercises used
     */
    let exerciseIDList: string[] = this.productTypeWorkouts.reduce(
      (accumulator, productSetup) => [...accumulator, ...productSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    exerciseIDList = Array.from(new Set(exerciseIDList));

    const exercises = await ExerciseModel.find({ or: exerciseIDList.map(x => ({ id: { eq: x } })) });

    await exercises.map(
      async (exercise): Promise<void> => {
        exercise.searchWorkoutList = exercise.searchWorkoutList || [];

        if (exercise.searchWorkoutList.includes(this.id)) {
          return;
        }

        exercise.searchWorkoutList.push(this.id);

        await exercise.save();
      },
    );
  }

  public async postRemove(): Promise<void> {
    /**
     * Get all the exercises used
     */
    let exerciseIDList: string[] = this.productTypeWorkouts.reduce(
      (accumulator, productSetup) => [...accumulator, ...productSetup.exerciseSetup.map(x => x.exerciseId)],
      [] as string[],
    );

    exerciseIDList = Array.from(new Set(exerciseIDList));

    const exercises = await ExerciseModel.find({ or: exerciseIDList.map(x => ({ id: { eq: x } })) });

    await exercises.map(
      async (exercise): Promise<void> => {
        exercise.searchWorkoutList = exercise.searchWorkoutList || [];

        // Remove workout from exercise search work list
        exercise.searchWorkoutList = exercise.searchWorkoutList.filter(workoutId => workoutId !== this.id);

        await exercise.save();
      },
    );
  }

  public async disable(): Promise<void> {
    if (this.isNew()) {
      return;
    }

    this.isDisabled = true;

    return this.save();
  }

  public getParentProductType(productType: ProductType): ProductType | void {
    if (productType === productTypeHierarchy[0]) {
      return;
    }

    /**
     * Check for alternative parents
     */
    const productTypeOption = ProductTypeOptions.find(x => x.key === productType);

    if (productTypeOption?.altInheritFrom) {
      return productTypeOption.altInheritFrom;
    }

    return productTypeHierarchy[productTypeHierarchy.indexOf(productType) - 1];
  }

  public getParentProductTypeSetup(productType: ProductType): IWorkoutBrandProductType | void {
    const parentProductType = this.getParentProductType(productType);

    if (!parentProductType) {
      return;
    }

    return this.productTypeWorkouts.find(x => x.productType === parentProductType) as IWorkoutBrandProductType;
  }

  /**
   * Can the product type inherit from its parent?
   *
   * Methof will check to see if the supplied product type can inherit from its parent
   *
   * @access public
   * @param {IWorkoutBrandProductType} setup The product type setup to check
   * @returns {boolean} True if it can be inherited, false if not
   */
  public canInherit(setup: IWorkoutBrandProductType): boolean {
    const parentSetup: IWorkoutBrandProductType | void = this.getParentProductTypeSetup(setup.productType);

    return (parentSetup && setup.exerciseSetup.every((x, i) => x.exerciseId === parentSetup.exerciseSetup[i].exerciseId)) as boolean;
  }

  /**
   * Resync the `isInherited` property on all product types
   *
   * Method will resync the `isInherited` property on all product types
   *
   * @access public
   * @return {void}
   */
  public syncInheritence(): void {
    this.productTypeWorkouts.forEach(setup => (setup.isInherited = this.canInherit(setup)));
  }

  public syncProductTypeWorkouts(
    exerciseOptions: IWorkoutBrandProductTypeOption[],
    startFromProductType?: ProductType,
    excludeStartFromProductType?: boolean,
  ): void {
    let foundStartFromProductType = false;

    productTypeHierarchy
      .filter(x => {
        if (!startFromProductType || foundStartFromProductType) {
          return true;
        }

        if (!foundStartFromProductType && x === startFromProductType) {
          foundStartFromProductType = true;

          return !excludeStartFromProductType;
        }

        return false;
      })
      .forEach(productType => {
        const thisWorkout = this.productTypeWorkouts.find(x => x.productType === productType) as IWorkoutBrandProductType;
        const thisExerciseOptions = exerciseOptions.find(x => x.productType === productType) as IWorkoutBrandProductTypeOption;

        const parentProductType: ProductType | void = this.getParentProductType(productType);

        /**
         * If we have a parent then restructure the data if possible (IE: Exercises are available in both this and the parent setup)
         */
        if (parentProductType) {
          assert(parentProductType, 'Cannot find parent product type to inherit from');

          const inheritedWorkout = this.productTypeWorkouts.find(x => x.productType === parentProductType) as IWorkoutBrandProductType;

          inheritedWorkout.exerciseSetup.forEach((inheritedMovement, index) => {
            /**
             * If movement types don't match then see if it can be changed, else bail
             */
            if (thisWorkout.exerciseSetup[index]?.movementType !== inheritedMovement.movementType) {
              const targetMovementOptions = thisWorkout.exerciseSetup.find(x => x.movementType === inheritedMovement.movementType);

              if (!targetMovementOptions) {
                return;
              }

              thisWorkout.exerciseSetup[index] = { ...targetMovementOptions };
            }

            /**
             * Now check to see if the parent selected exercise is within our exercise options
             */
            const targetMovement = thisWorkout.exerciseSetup[index];

            if (inheritedMovement.exerciseId && (!targetMovement.exerciseId || targetMovement.exerciseId !== inheritedMovement.exerciseId)) {
              const exerciseOptions = thisExerciseOptions.exerciseSetup.find(
                x => x.movementType === inheritedMovement.movementType,
              ) as IWorkoutBrandProductTypeOptionExercises;

              /**
               * Again if we can't fit then bail
               */
              if (!exerciseOptions.exercises.some(x => x.id === inheritedMovement.exerciseId)) {
                return;
              }

              targetMovement.exerciseId = inheritedMovement.exerciseId;
            }
          });
        }

        /**
         * Exercises are all setup, now we resync the inheritence mapping. Before it was included in the above code but needed to be separate
         * so we can turn off/on the logic dynamically
         */
        thisWorkout.isInherited = this.canInherit(thisWorkout);

        /**
         * Next we need to restructure the movement types if we do not have any valid exercises to choose
         * from, but only for non inherited product type workouts. If there are invalid then change the
         * movement type to the previous 'good' one.
         *
         * This can be optionally turned off, so check for that
         */
        const protuctTypeSetup = ProductTypeOptions.find(x => x.key === productType);

        if (!thisWorkout.isInherited && !protuctTypeSetup?.ignoreMovementChange) {
          let badMovementTypeIndexes: number[] = [];

          thisWorkout.exerciseSetup.forEach((thisMovementSetup, index) => {
            const exerciseOptions = thisExerciseOptions.exerciseSetup.find(
              x => x.movementType === thisMovementSetup.movementType,
            ) as IWorkoutBrandProductTypeOptionExercises;

            /**
             * If no options then add to our bad list
             */
            if (exerciseOptions.exercises.length === 0) {
              badMovementTypeIndexes.push(index);

              /**
               * Else if we do have options AND if we have previous bad movement types then replcaite all the bad
               * ones with this good one
               */
            } else if (badMovementTypeIndexes.length > 0) {
              badMovementTypeIndexes.forEach(badIndex => {
                thisWorkout.exerciseSetup[badIndex] = _.cloneDeep(thisMovementSetup);
              });

              badMovementTypeIndexes = [];
            }
          });
        }
      });
  }

  public async getExerciseOptions(): Promise<IWorkoutBrandProductTypeOption[]> {
    return workoutModel.getExerciseOptions(this.workoutBrandId, this.id);
  }

  public getMovementTypeStatus(productType: ProductType, brand: BrandDocument): WorkoutMovementTypeCompareStatus[] {
    const productSetup: IWorkoutBrandProductType = this.productTypeWorkouts.find(x => x.productType === productType) as IWorkoutBrandProductType;
    const parentSetup: IWorkoutBrandProductType = this.getParentProductTypeSetup(productType) as IWorkoutBrandProductType;
    const brandProductSetup: IBrandProductMovementSetup = brand.productMovementSetup.find(x => x.productType === productType) as IBrandProductMovementSetup;

    return productSetup.exerciseSetup.map((exerciseSetup, exerciseSetupIndex) => {
      const initialMovementType: MovementType = brandProductSetup.movementTypes[exerciseSetupIndex];

      if (parentSetup) {
        const parentMovementTypeSetup = parentSetup.exerciseSetup[exerciseSetupIndex];

        /**
         * If our parent movement type is DIFFERENT to this movement type, then that means that the parent movement type
         * cannot be applied
         */
        if (parentMovementTypeSetup.movementType !== exerciseSetup.movementType) {
          /**
           * Initial movement type was changed because no available exercises found
           */
          if (initialMovementType !== exerciseSetup.movementType) {
            return 'cannot-inherit-different-movement-type';
          } else {
            return 'cannot-inherit-movement-type';
          }
        }

        /**
         * If our parent movement type is the same as this movement type BUT is different to our initial movement type,
         * then that means that our initial movement type was changed to match the parent
         */
        if (initialMovementType !== exerciseSetup.movementType) {
          return 'inherited-different-movement-type';
        }

        /**
         * If our parent movement type is the same as this movement type BUT has a different selected exercise, then that
         * means that the selected exercise was changed becasue it couldn't match the parent's exercise
         */
        if (parentMovementTypeSetup.exerciseId !== exerciseSetup.exerciseId) {
          return 'cannot-inherit-exercise';
        }
      }

      /**
       * Initial movement type was changed because no available exercises found
       */
      if (initialMovementType !== exerciseSetup.movementType) {
        return 'different-movement-type';
      }

      return 'ok';
    });
  }

  public getMatchingProductTypes(): IWorkoutMatchingProductTypes[] {
    return this.productTypeWorkouts.reduce((matchingProductTypes, productTypeWorkout) => {
      if (productTypeWorkout.isDisabled) {
        return matchingProductTypes;
      }

      const prevProductTypeWorkout = this.getParentProductTypeSetup(productTypeWorkout.productType) as IWorkoutBrandProductType;

      /**
       * We check to see if we are different from our parent. If we do not have a parent then we are
       * dirrerent by default so we can start it off
       */
      let isDifferent: boolean = !prevProductTypeWorkout;

      /**
       * If we are optional then we are different by default. This is so we stand out separately
       */
      if (!isDifferent && ProductTypeOptions.find(x => x.key === productTypeWorkout.productType)?.isOptional) {
        isDifferent = true;
      }

      /**
       * Else check the parent to see if all our exercises match
       */
      if (!isDifferent) {
        isDifferent = productTypeWorkout.exerciseSetup.some(
          (exerciseSetup, index) =>
            !prevProductTypeWorkout.exerciseSetup[index] || prevProductTypeWorkout.exerciseSetup[index].exerciseId !== exerciseSetup.exerciseId,
        );
      }

      if (isDifferent) {
        matchingProductTypes.push({
          productTypes: [],
          exerciseSetup: productTypeWorkout.exerciseSetup,
        });
      }

      matchingProductTypes[matchingProductTypes.length - 1].productTypes.push(productTypeWorkout.productType);

      return matchingProductTypes;
    }, [] as IWorkoutMatchingProductTypes[]);
  }
}

export class WorkoutModel extends DBModel<WorkoutDocument, IWorkout> {
  /**
   * Create a new document based on the supplied brand ID
   *
   * Method will create a new WorkoutDocument and initialise the product type exercises based on the
   * supplied brand ID
   *
   * @asycn
   * @access public
   * @param {string} brandId The brand ID
   * @param {Partial<IWorkout>} data The optional initialisation data
   * @return {Promise<WorkoutDocument>} The workout document
   */
  async createNew(brandId: string, data?: Partial<IWorkout>): Promise<WorkoutDocument> {
    assert(brandId, 'Missing/Invalid brandId');

    const brand: BrandDocument = (await BrandModel.findById(brandId)) as BrandDocument;

    assert(brand, 'Unmatched brandId');

    const doc: WorkoutDocument = this.create({ ...(data || {}), workoutBrandId: brandId });

    /**
     * Make sure the product types are in hierarchical
     */
    doc.productTypeWorkouts = productTypeHierarchy.map(productType => {
      const setup = brand.productMovementSetup.find(x => x.productType === productType) as IBrandProductMovementSetup;
      const productTypeOption = ProductTypeOptions.find(x => x.key === productType);

      return {
        productType: setup.productType,
        isDisabled: !!productTypeOption?.isOptional,
        isInherited: false,
        exerciseSetup: setup.movementTypes.map(movementType => ({
          movementType,
          exerciseId: '',
        })),
        productionFile: S3ObjectDefaultValue,
        status: WorkoutVideoStatusType.NOT_READY,
        jwPlayer: '',
      };
    });

    return doc;
  }

  async createNewSetup(brandId: string, data?: Partial<IWorkout>): Promise<{ workout: WorkoutDocument; options: IWorkoutBrandProductTypeOption[] }> {
    const [workout, options] = await Promise.all([this.createNew(brandId, data), this.getExerciseOptions(brandId)]);

    /**
     * Assign the default values before re sync up the product types. `Try` not to use the same exercise
     * twice. Also make sure that all product types inherit from the root product type
     */
    workout.productTypeWorkouts.forEach(prodTypeWorkout => {
      /**
       * Set inheritence flag
       */
      prodTypeWorkout.isInherited = prodTypeWorkout.productType !== productTypeHierarchy[0];

      /**
       * Now default options
       */
      const prodTypeOptions = options.find(x => x.productType === prodTypeWorkout.productType) as IWorkoutBrandProductTypeOption;
      const exercisesInUse: string[] = [];

      prodTypeWorkout.exerciseSetup.forEach(exerciseSetup => {
        const exerciseOptions = prodTypeOptions.exerciseSetup.find(x => x.movementType === exerciseSetup.movementType);

        if (!exerciseOptions || exerciseOptions.exercises.length === 0) {
          return;
        }

        const unusedExercises = exerciseOptions.exercises.filter(x => !exercisesInUse.includes(x.id));

        if (unusedExercises.length > 0) {
          exerciseSetup.exerciseId = unusedExercises[0].id;

          /**
           * Use the same one twice if we have no choice
           */
        } else {
          exerciseSetup.exerciseId = exerciseOptions.exercises[0].id;
        }

        exercisesInUse.push(exerciseSetup.exerciseId);
      });
    });

    /**
     * Sync up the product types here as initialisation is a slightly different process
     */
    workout.syncProductTypeWorkouts(options);

    return { workout, options };
  }

  async findByBrandId(brandId: string): Promise<WorkoutDocument[]> {
    return await this.find({ workoutBrandId: { eq: brandId }, isDisabled: false });
  }

  public async findById(id: string): Promise<WorkoutDocument> {
    const workout = await super.findById(id);

    if (workout && !workout.isDisabled) {
      return workout;
    }

    return (undefined as unknown) as WorkoutDocument;
  }

  public async find(filter?: Record<string, any>): Promise<WorkoutDocument[]> {
    return super.find({ ...(filter || {}), isDisabled: { eq: false } });
  }

  async getExercises(workoutId: string): Promise<ExerciseDocument[]> {
    const workout = await this.findById(workoutId);

    if (!workout) {
      return [];
    }

    return ExerciseModel.findByIdList(workout.searchExerciseList);
  }

  async getExerciseOptions(brandId: string, workoutId?: string): Promise<IWorkoutBrandProductTypeOption[]> {
    const brand: BrandDocument = await BrandModel.findById(brandId);

    assert(brand, 'Unmatched brand ID ' + brandId);

    /**
     * Flesh out the structure with a map helper
     */
    const returnStructureMap: Partial<Record<ProductType, number>> = {};
    const returnStructure: IWorkoutBrandProductTypeOption[] = productTypeHierarchy.reduce((accumulator, productType, index) => {
      const productMovementSetup = brand.productMovementSetup.find(x => x.productType === productType) as IBrandProductMovementSetup;

      accumulator.push({
        productType,
        exerciseSetup: [...new Set(productMovementSetup.movementTypes)].map(movementType => ({ movementType, exercises: [] })),
      });

      returnStructureMap[productType] = index;

      return accumulator;
    }, [] as IWorkoutBrandProductTypeOption[]);

    /**
     * Next get a greylist of all the exercies used in the last 2 workouts
     */
    const greylistWorkouts: WorkoutDocument[] = [];
    const workouts: WorkoutDocument[] = await this.findByBrandId(brandId);

    if (workoutId) {
      const thisWorkout = workouts.find(x => x.id === workoutId);

      assert(thisWorkout, 'Unmatched workout ID ' + workoutId);

      /**
       * If we are the first one
       */
      if (thisWorkout.sequence === 1) {
        workouts.splice(-2).forEach(x => greylistWorkouts.push(x));

        /**
         * Else if we are the second one
         */
      } else if (thisWorkout.sequence === 2) {
        workouts.splice(-1).forEach(x => greylistWorkouts.push(x));
        workouts.splice(0, 1).forEach(x => greylistWorkouts.push(x));

        /**
         * Else safe to work off the sequence
         */
      } else {
        workouts.splice(thisWorkout.sequence - 3, 2).forEach(x => greylistWorkouts.push(x));
      }
    } else {
      workouts.splice(-2).forEach(x => greylistWorkouts.push(x));
    }

    const greylistExerciseIds: string[] = greylistWorkouts.reduce((accumulator, workout) => {
      return accumulator.concat(
        workout.productTypeWorkouts.reduce((productAccumulator, productSetup) => {
          return productAccumulator.concat(productSetup.exerciseSetup.map(x => x.exerciseId));
        }, [] as string[]),
      );
    }, [] as string[]);

    /**
     * Use Promise.all so we can run in parallel
     */
    await Promise.all(
      brand.productMovementSetup.map(
        async (productSetup: IBrandProductMovementSetup): Promise<void> => {
          const exercises: ExerciseDocument[] = await ExerciseModel.findByBrandIdAndProductType(brandId, productSetup.productType);

          /**
           * Set aside the optional exercise filter. We'll use this for later on
           */
          const exerciseFilter = ProductTypeOptions.find(x => x.key === productSetup.productType)?.exerciseFilter;

          /**
           * Need to also filter on available equipment types for the product type on each exercise
           */
          const availableEquipmentTypes: EquipmentType[] = EquipmentTypeOptions.reduce((accumulator, val) => {
            if (val.productTypes.includes(productSetup.productType) && !accumulator.includes(val.key)) {
              accumulator.push(val.key);
            }
            return accumulator;
          }, [] as EquipmentType[]);

          /**
           * Sort exercises by alphabetical order. Shove all greylisted exercises at the end
           */
          exercises.sort((exerciseA: ExerciseDocument, exerciseB: ExerciseDocument): number => {
            /**
             * Greylisted goto the end
             */
            if (greylistExerciseIds.indexOf(exerciseA.id) > -1) {
              return 1;
            }

            const nameA = exerciseA.name.toLowerCase();
            const nameB = exerciseB.name.toLowerCase();

            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

          const exerciseSetupList: IWorkoutBrandProductTypeOptionExercises[] =
            returnStructure[returnStructureMap[productSetup.productType] as number].exerciseSetup;

          exercises.forEach(exercise => {
            exerciseSetupList.forEach(exerciseSetup => {
              let isValid: boolean = exercise.movementTypes.includes(exerciseSetup.movementType);

              if (isValid) {
                isValid = exercise.equipmentTypes.every((equipType: EquipmentType) => availableEquipmentTypes.includes(equipType));
              }

              /**
               * Also check to see if we have a specific exercise filter for this product type
               */
              if (isValid && exerciseFilter) {
                isValid = exerciseFilter(exercise);
              }

              if (isValid) {
                exerciseSetup.exercises.push({
                  id: exercise.id,
                  name: exercise.name,
                  isGreyListed: greylistExerciseIds.indexOf(exercise.id) > -1,
                });
              }
            });
          });
        },
      ),
    );

    return returnStructure;
  }
}

const setup: DBSchema = {
  workoutBrandId: {
    type: 'string',
    required: true,
    ref: 'brand',
  },
  sequence: {
    type: 'number',
  },
  isDisabled: {
    type: 'boolean',
  },
  productTypeWorkouts: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        productType: {
          type: 'enum',
          enum: Object.keys(ProductType),
        },
        exerciseSetup: {
          type: 'array',
          defaultField: {
            type: 'object',
            fields: {
              movementType: {
                type: 'enum',
                enum: Object.keys(MovementType),
              },
              exerciseId: {
                type: 'string',
              },
            },
          },
        },
        isInherited: {
          type: 'boolean',
        },
        isDisabled: {
          type: 'boolean',
        },
        productionFile: {
          type: 'object',
          fields: S3ObjectSchema,
        },
        status: {
          type: 'enum',
          enum: Object.keys(WorkoutVideoStatusType),
        },
        jwPlayer: {
          type: 'string',
        },
      },
    },
  },
  searchExerciseList: {
    type: 'array',
    defaultField: {
      type: 'string',
    },
  },
};

const workoutModel = new WorkoutModel('Workout', WorkoutDocument, setup);

export default workoutModel;
