import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { PartnerModel, PartnerDocument } from '@/models';

export interface IPartnerState {
  current: PartnerDocument | null;
  list: PartnerDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'partner' })
class PartnerStore extends VuexModule implements IPartnerState {
  public current: PartnerDocument | null = null;
  public list: PartnerDocument[] = [];

  @Mutation
  SET_CURRENT(partner: PartnerDocument) {
    this.current = partner;
  }

  @Mutation
  SET_LIST(partners: PartnerDocument[]) {
    this.list = partners;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<PartnerDocument[]> {
    return PartnerModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(): Promise<PartnerDocument> {
    return PartnerModel.createNew();
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<PartnerDocument | void> {
    return PartnerModel.findById(id);
  }
}

export default getModule(PartnerStore);
