














import { Component, Vue, Prop } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { getFullPath } from './util';
import NavItemLink from './NavItemLink.vue';

@Component({
  name: 'NavItem',
  components: {
    NavItemLink,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private route!: RouteConfig;
  @Prop({ default: '' }) private basePath!: string;

  get hasChildren(): boolean {
    return (this.route.children || []).length > 0;
  }

  get fullPath(): string {
    return getFullPath(this.route, this.basePath);
  }
}
