import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';
import ExerciseModel, { ExerciseDocument } from '../exercise';
import { ExerciseVideoStatusType } from '@/constants';
import moment from 'moment';

export interface IRawVideoExportLogCompiled {
  exerciseId: string;
  brandId: string;
}

export interface IRawVideoExportLogEmailSetup {
  email: string;
  name: string;
}

export interface IRawVideoExportLog extends IDocument {
  brandList: string[];
  from: number;
  to: number;
  compiled: IRawVideoExportLogCompiled[];
  uploadPath: string;
  emailSetup: IRawVideoExportLogEmailSetup;
}

export class RawVideoExportLogDocument extends DBDocument<RawVideoExportLogDocument, IRawVideoExportLog> implements IRawVideoExportLog {
  brandList: string[] = [];
  from: number = (undefined as unknown) as number;
  to: number = (undefined as unknown) as number;
  compiled: IRawVideoExportLogCompiled[] = [];
  uploadPath: string = '';
  emailSetup: IRawVideoExportLogEmailSetup = {
    email: '',
    name: '',
  };

  public async compileVideoSetup(): Promise<void> {
    const criteria: Record<string, any> = {
      searchStatusList: { contains: ExerciseVideoStatusType.READY_TO_EDIT },
    };

    if (this.brandList.length > 0) {
      criteria.or = this.brandList.reduce((accumulator, brandId) => [...accumulator, { searchBrandList: { contains: brandId } }], [] as any[]);
    }

    const exercises: ExerciseDocument[] = await ExerciseModel.find(criteria);

    if (this.from) {
      this.from = moment(this.from)
        .second(0)
        .minute(0)
        .hour(0)
        .valueOf();
    }

    if (this.to) {
      this.to = moment(this.to)
        .second(59)
        .minute(59)
        .hour(23)
        .valueOf();
    }

    this.compiled = exercises.reduce(
      (accumulator, exercise: ExerciseDocument) => [
        ...accumulator,
        ...(exercise.brandSetup
          .filter(x => {
            if (x.status !== ExerciseVideoStatusType.READY_TO_EDIT) {
              return false;
            }

            if (this.brandList.length > 0 && !this.brandList.includes(x.brandId)) {
              return false;
            }

            if (this.from || this.to) {
              if (!x.filmDate) {
                return false;
              }

              const filmDate = moment(x.filmDate).valueOf();

              if (this.from && this.to && (filmDate < this.from || filmDate > this.to)) {
                return false;
              } else if (this.from && filmDate < this.from) {
                return false;
              } else if (this.to && filmDate > this.to) {
                return false;
              }
            }

            return true;
          })
          .map(x => ({ exerciseId: exercise.id, brandId: x.brandId })) as IRawVideoExportLogCompiled[]),
      ],
      [] as IRawVideoExportLogCompiled[],
    );
  }

  public async preSave(isUpdate: boolean): Promise<void> {
    if (isUpdate) {
      return;
    }

    this.compileVideoSetup();
  }
}

export class RawVideoExportLogModel extends DBModel<RawVideoExportLogDocument, IRawVideoExportLog> {}

const setup: DBSchema = {
  brandId: {
    type: 'string',
  },
  from: {
    type: 'date',
  },
  to: {
    type: 'date',
  },
  compiled: {
    type: 'array',
    defaultField: {
      type: 'object',
      fields: {
        exerciseId: {
          type: 'string',
          required: true,
        },
        brandId: {
          type: 'string',
          required: true,
        },
      },
    },
  },
  uploadPath: {
    type: 'string',
  },
  emailSetup: {
    type: 'object',
    fields: {
      email: {
        type: 'string',
        required: true,
      },
      name: {
        type: 'string',
        required: true,
      },
    },
  },
};

const bulkOpsModel = new RawVideoExportLogModel('RawVideoExportLog', RawVideoExportLogDocument, setup);

export default bulkOpsModel;
