/* eslint-disable @typescript-eslint/no-use-before-define */
import { listUserByEmail } from '@/graphql/queries';
import { UserRole, UserRoleList } from '@/constants';
import { DBModel, DBDocument, IDocument, DBSchema } from '../lib';
import UserStore from '@/store/modules/user';
import { Auth } from 'aws-amplify';
import assert from 'assert';

export interface IUser extends IDocument {
  email: string;
  name: string;
  roles: UserRole[];
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

export class UserDocument extends DBDocument<UserDocument, IUser> implements IUser {
  email: string = '';
  name: string = '';
  roles: UserRole[] = [];
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;

  public isAdministrator(): boolean {
    return this.isAdmin || this.isSuperAdmin;
  }

  public hasRole(role: UserRole | UserRole[], administratorCheck?: boolean): boolean {
    if (administratorCheck && this.isAdministrator()) {
      return true;
    }

    if (Array.isArray(role)) {
      return this.roles.some(x => role.includes(x));
    } else {
      return this.roles.includes(role);
    }
  }

  public roleList(): string {
    if (this.isSuperAdmin) {
      return 'Super User';
    } else if (this.isAdmin) {
      return 'Admin User';
    } else {
      return this.roles
        .reduce((accumulator: string[], role: UserRole) => {
          return accumulator.concat(UserRoleList[role]);
        }, [])
        .join(', ');
    }
  }

  public logout(): Promise<void> {
    return userModel.logout();
  }
}

export class UserModel extends DBModel<UserDocument, IUser> {
  public async findByEmail(email: string): Promise<UserDocument> {
    const rtn: any = await this._execRawAPI(listUserByEmail, { email });

    if (rtn.data.listUserByEmail.items.length === 0) {
      throw new Error(`Cannot find matching user (email: ${email}`);
    }

    return this.create(rtn.data.listUserByEmail.items.pop());
  }

  public async isLoggedIn(): Promise<boolean> {
    try {
      const session = await Auth.currentSession();

      return session && session.isValid();
    } catch (e) {
      return false;
    }
  }

  public async login(email: string): Promise<void> {
    await UserStore.doLogin(email);
  }

  public async loginCurrent(): Promise<void> {
    const isLoggedIn = await this.isLoggedIn();

    assert(isLoggedIn, 'Cannot login current user as there is no logged in user');

    const currentUserInfo = await Auth.currentUserInfo();

    return this.login(currentUserInfo.attributes?.email as string);
  }

  public async logout(): Promise<void> {
    return Auth.signOut();
  }
}

const setup: DBSchema = {
  email: {
    type: 'string',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
  },
  roles: {
    type: 'array',
    defaultField: {
      type: 'enum',
      enum: Object.keys(UserRole),
    },
  },
  isAdmin: {
    type: 'boolean',
  },
  isSuperAdmin: {
    type: 'boolean',
  },
};

const userModel = new UserModel('User', UserDocument, setup);

export default userModel;
