






import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import NavItem from './NavItem.vue';
import UserStore from '@/store/modules/user';
import { UserDocument } from '@/models';

const _filterHidden = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.reduce((accumulator: RouteConfig[], route: RouteConfig) => {
    let applyRoute: boolean = !route.meta?.hidden;

    /**
     * If we have user roles assigned in the meta then check on that aswell
     */
    if (applyRoute && route.meta?.userRoles) {
      applyRoute = (UserStore.current as UserDocument).hasRole(route.meta.userRoles, true);
    }

    if (applyRoute && route.meta?.isAdmin) {
      applyRoute = (UserStore.current as UserDocument).isAdministrator();
    }

    if (applyRoute) {
      accumulator.push({
        ...route,
        children: _filterHidden(route.children || []),
      });
    }

    return accumulator;
  }, []);
};

@Component({
  name: 'NavBar',
  components: {
    NavItem,
  },
})
export default class extends Vue {
  get routes(): RouteConfig[] {
    const rootRoute: RouteConfig = (this.$router as any).options.routes.reduce((accumulator: RouteConfig, route: RouteConfig) => {
      if (accumulator || !route.meta?.root) {
        return accumulator;
      }

      return route;
    }, null);

    return _filterHidden(rootRoute.children || []);
  }
}
