import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { PartnerWorkoutModel, PartnerWorkoutDocument } from '@/models';

export interface IPartnerWorkoutState {
  current: PartnerWorkoutDocument | null;
  list: PartnerWorkoutDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'partnerworkout' })
class PartnerWorkoutStore extends VuexModule implements IPartnerWorkoutState {
  public current: PartnerWorkoutDocument | null = null;
  public list: PartnerWorkoutDocument[] = [];

  @Mutation
  SET_CURRENT(partnerWorkout: PartnerWorkoutDocument) {
    this.current = partnerWorkout;
  }

  @Mutation
  SET_LIST(partnerWorkouts: PartnerWorkoutDocument[]) {
    this.list = partnerWorkouts;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<PartnerWorkoutDocument[]> {
    return PartnerWorkoutModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(partnerBrandId: string): Promise<PartnerWorkoutDocument> {
    return PartnerWorkoutModel.createNew(partnerBrandId);
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<PartnerWorkoutDocument | void> {
    return PartnerWorkoutModel.findById(id);
  }
}

export default getModule(PartnerWorkoutStore);
