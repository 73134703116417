import { VuexModule, Module, Action, getModule, Mutation, MutationAction } from 'vuex-module-decorators';
import store from '@/store';
import { WorkoutModel, WorkoutDocument } from '@/models';
import { IWorkoutBrandProductTypeOption } from '@/models/workout';

export interface IWorkoutState {
  current: WorkoutDocument | null;
  exerciseOptions: IWorkoutBrandProductTypeOption[];
  list: WorkoutDocument[];
}

@Module({ namespaced: true, dynamic: true, store, name: 'workout' })
class WorkoutStore extends VuexModule implements IWorkoutState {
  public current: WorkoutDocument | null = null;
  public exerciseOptions: IWorkoutBrandProductTypeOption[] = [];
  public list: WorkoutDocument[] = [];

  @Mutation
  SET_CURRENT(workout: WorkoutDocument) {
    this.current = workout;
  }

  @Mutation
  SET_EXERCISE_OPTIONS(exerciseOptions: IWorkoutBrandProductTypeOption[]) {
    this.exerciseOptions = exerciseOptions;
  }

  @Mutation
  SET_LIST(exercises: WorkoutDocument[]) {
    this.list = exercises;
  }

  @Action({ commit: 'SET_LIST' })
  async loadAll(): Promise<WorkoutDocument[]> {
    return WorkoutModel.find();
  }

  @Action({ commit: 'SET_CURRENT' })
  async loadById(id: string): Promise<WorkoutDocument> {
    const doc = await WorkoutModel.findById(id);

    if (!doc) {
      throw new Error(`Cannot find workout with ID of ${id}`);
    }

    return doc;
  }

  @MutationAction({ mutate: ['current', 'exerciseOptions'] })
  async loadByIdWithOptions(
    id: string,
  ): Promise<{
    current: WorkoutDocument;
    exerciseOptions: IWorkoutBrandProductTypeOption[];
  }> {
    const current = await WorkoutModel.findById(id);
    const exerciseOptions = await current.getExerciseOptions();

    return {
      current,
      exerciseOptions,
    };
  }

  @Action({ commit: 'SET_CURRENT' })
  async startNew(brandId: string): Promise<WorkoutDocument> {
    return WorkoutModel.createNew(brandId);
  }

  @MutationAction({ mutate: ['current', 'exerciseOptions'] })
  async startNewWithOptions(
    brandId: string,
  ): Promise<{
    current: WorkoutDocument;
    exerciseOptions: IWorkoutBrandProductTypeOption[];
  }> {
    const setup = await WorkoutModel.createNewSetup(brandId);

    return {
      current: setup.workout,
      exerciseOptions: setup.options,
    };
  }

  @Action({ commit: 'SET_EXERCISE_OPTIONS' })
  loadExerciseOptions(exerciseOptions: IWorkoutBrandProductTypeOption[]): IWorkoutBrandProductTypeOption[] {
    return exerciseOptions;
  }
}

export default getModule(WorkoutStore);
